
//////dataview fullscreen

.dashboard-fullscreen-container{
   height: 100%;
   
   .p-dialog-footer{
    display: none;
   }
    .xd{
        display: flex;
        flex-wrap: wrap;
        
        
        .product-grid-item {
            min-height: 150px;
            height: 100%;
            width: 100%;
            margin: .5em;
            border: 1px solid var(--surface-border);
            padding-top: 2px;
         
        
        }
        .product-grid-item-top {
            margin-bottom: 2px;
            min-height: 50px;
            max-height: 150px;
            width: 100%;
            height: 70px;
            font-size: small;
            font-weight: 600;
            display: flex;
            flex-wrap: wrap;
            justify-content: center !important;
            
            .p-tag.p-tag-info{
                background-color: rgb(203, 206, 52);
                margin: auto;
                .p-tag-value{
                    font-size: 6px;
                    height: 15px;
                    width: 75px;
                }
            }

           
  
         
         }
         .product-name{
            display: flex;
            justify-content: center;
            min-height: 36px;
            span{
                font-weight: 700;
                font-size: 12px;
                text-align: center;
               
            }
            
         }
         .phone-number{
            display: flex;
            justify-content: start;
            min-height: 36px;
            span{
                font-size: 12px;
                font-weight: 600;
                margin-left: 8px;
                text-align: center;
            }
         }
         .address{
            display: flex;
            justify-content: center;
            min-height: 36px;
            span{
                font-size: 12px;
                font-weight: 600;
                margin-left: 8px;
                text-align: center;
            }
         }
         .leader-div{
              display:flex;
              flex-wrap: wrap; 
              min-height:50px; 
              justify-content:center;
              margin-bottom: 20px;
              span{
              
                text-align: center;
            }
            .p-tag-value{
                font-size: 10px;
               // height: 15px;
                width: 90px;
            }
         }

         .product-grid-item-content {
            min-height: 90px;
            width: 100%;
            height: 100px;
            font-size: small;
            font-weight: 600;
            display: flex;
            flex-wrap: wrap;
            
        .p-tag-value{
            font-size: 12px;
            height: 15px;
            width: 75px;
        }
        .dashboard-teil-c{
            height: 90px;
            max-width: 300px;
            min-width: 200px;
            min-height: 90px;
            margin: auto;
        }
        .p-badge.p-badge-info{
            background-color: red;
        }
         
        }

        .product-grid-item-content-minimised {
            min-height: 40px;
            width: 100%;
            height: 40px;
            font-size: small;
            font-weight: 600;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
            
        .p-tag-value{
            font-size: 12px;
            height: 15px;
            width: 75px;
        }
        .dashboard-teil-c{
            height: 90px;
            max-width: 300px;
            min-width: 200px;
            min-height: 90px;
            margin: auto;
        }
        .p-badge.p-badge-info{
            background-color: red;
        }
         
        }

            .product-name{
           display: flex;
             .text{
                background: linear-gradient(orange, orange);
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                text-align: center;
                font-size: 10px;
                font-weight: 600;
                padding: 5px;
                display: flex;
                height: 80px;
                width: 80px;
                color: #fff;
              }
        }


        .product-grid-item .product-grid-item-top,
        .product-grid-item .product-grid-item-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    }
    .product-grid-item {
        min-width: 80%;
        min-height: 80%;
        max-width: 700px;
        max-height:600px;
        height: auto;
        width: auto;
        margin: .5em;
        border: 1px solid var(--surface-border);
        padding: 5px;
        position: relative;
    }
    
    .p-dataview-content{
        
        .grid-nogutter{
            display: flex;
            flex-wrap: wrap;
            overflow-x: auto;
        }
    }
    .product-grid-item .product-grid-item-top,
        .product-grid-item .product-grid-item-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .product-grid-item img {
        width: 75%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin: 2rem 0;
    }
    
    .product-grid-item .product-grid-item-content {
        text-align: center;
    }
    
    .product-grid-item .product-price {
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    @media screen and (max-width: 576px) {
        .product-list-item {
            flex-direction: column;
            align-items: center;
        }
    
     .product-list-item img {
            width: 75%;
            margin: 2rem 0;
        }
    
      .product-list-item .product-list-detail {
            text-align: center;
        }
    
        .product-list-item .product-price {
            align-self: center;
        }
    
         .product-list-item .product-list-action {
            display: flex;
            flex-direction: column;
        }
    
        .product-list-item .product-list-action {
            margin-top: 2rem;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }
    
    .product-grid-item-bottom{
        .p-inputgroup{
            width:  max-content;
            height: 20px;
            .product-price{
                font-size: small;
                width: 150px;
                text-align: left;
                font-weight: 800;
               
            }
            .p-inputgroup-addon{
                 min-width: 22px;
                 background-color: white;
                 border-color: white;
                 padding-left: 0px;
             }
        }
    }
                       
}
