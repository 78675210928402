
//////dataview

@media (min-width: 1920px) {
    body div#root .container-fluid {
        max-width: 1860px;
    }
    .container-fluid .footer {
        position: absolute;
        left: 150px;
        max-width: 100000px;
    }
    body div#root .app main .auto-register-container .container-fluid {
        max-width: 1320px;

        .final-page {
            max-width: 900px;
        }
    }
}
.dataview-demo2{
    height: 100% !important;
    width: 100% !important;
    .card-m{
        height: 100% !important;
        width: 100% !important;
    }
}
.dataview-demo2 .p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.dataview-demo2 .product-name {
    font-size: 1.5rem;
    font-weight: 600;
    font-size: medium;
}
.dataview-demo2 .phone-number {
    font-size: 1.5rem;
    font-weight: 200;
    font-size: medium;
}

.dataview-demo2 .product-description {
    margin: 0 0 1rem 0;
}

.dataview-demo2 .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}
.dataview-demo2 .phone-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.dataview-demo2 .product-category {
    font-weight: 600;
    font-size:small;
    vertical-align: middle;

}

.dataview-demo2 .product-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.dataview-demo2 .product-list-item img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
}

.dataview-demo2 .product-list-item .product-list-detail {
    flex: 1 1 0;
}

.dataview-demo2 .product-list-item .p-rating {
    margin: 0 0 .5rem 0;
}

.dataview-demo2.product-list-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
    align-self: flex-end;
}

.dataview-demo2 .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
}

.dataview-demo2 .product-list-item .p-button {
    margin-bottom: .5rem;
}

.dataview-demo2 .product-grid-item {
    height: 100%;
    min-height: 350px;
    //width: 400px;
    //margin: 0.1em;
    border: 1px solid var(--surface-border);
    padding: 0.2rem;
}

.p-dataview-content{
    .grid-nogutter{
        display: flex;
        flex-wrap: wrap;
        word-break: break-word;
        //overflow-x: auto;
    }
}
.dataview-demo2 .product-grid-item .product-grid-item-top,
    .dataview-demo .product-grid-item .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
}

.dataview-demo2 .product-grid-item img {
    width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
}

.dataview-demo2 .product-grid-item .product-grid-item-content {
    text-align: center;
        .dashboard-teil{
            .row{
                width: 950px;
            }
        }
        .dashboard-teil2{
            overflow-x: auto;
            overflow-y:auto;
            height: auto;
            max-height: 650px;
            .row{
                width: 950px;
            }
        }
        .icon-large{
            color: green;
        }
}
.product-grid-item-top-date-country{
    display: inline-flex;
    .product-grid-item-top-date{
        margin-bottom: 2px;
        min-height: 20px;
        max-height: 30px;
        width: 100%;
        height: 70px;
        font-size: small;
        font-weight: 600;
        .p-tag.p-tag-info{
             margin: auto;
             .p-tag-value{
                 font-size: 6px;
                 height: 15px;
                 width: 75px;
             }
         }
    }
    .product-grid-item-top-country {
        margin-bottom: 2px;
        min-height: 20px;
        max-height: 30px;
        width: auto;
        height: 70px;
        font-size: small;
        font-weight: 600;
        display: flex;
        flex-wrap: wrap;
        justify-content: right !important;
        
        .p-tag.p-tag-info{
           // background-color: rgb(17, 17, 3);
            margin: auto;
            .p-tag-value{
                font-size: 6px;
                height: 15px;
                width: 75px;
            }
        }
     
     }
}


.dataview-demo2 .product-grid-item .product-price {
    font-size: 1.5rem;
    font-weight: 100;
    font-size: small;
}

@media screen and (max-width: 576px) {
    .dataview-demo2 .product-list-item {
        flex-direction: column;
        align-items: center;
    }

    .dataview-demo2 .product-list-item img {
        width: 75%;
        margin: 2rem 0;
    }

    .dataview-demo2 .product-list-item .product-list-detail {
        text-align: center;
    }

    .dataview-demo2 .product-list-item .product-price {
        align-self: center;
    }
ś
    .dataview-demo2 .product-list-item .product-list-action {
        display: flex;
        flex-direction: column;
    }

    .dataview-demo2 .product-list-item .product-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

.product-grid-item-bottom{
        display: flex;
        height: 30px;
        width: fit-content;
    .p-inputgroup{
       
       height: 30px;
       
        .product-price{
            font-size: small;
            text-align: left;
            font-weight: 800;
            margin-left: 0px;
            font-size: 11px;
           
        }
        .p-inputgroup-addon{
             min-width: 22px;
             background-color: white;
             border-color: white;
             padding-left: 0px;
         }
    } 
}

.copy {
    cursor: pointer;
}