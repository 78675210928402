// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;900&display=swap');
//prosidebar
$timehero-color1: #1c7523;
$timehero-light-color1: #129252;
$timehero-side-background: #769188;
$timehero-color2: #129252;
$timehero-color3: #3059e8;
$timehero-color4: #2196F3;
$timehero-input-background: #f4f5fc;
$timehero-login-backgroung-image: '../img/login-background-rawicom.jpg';
$timehero-logo-image: '../../../public/images/timehero-logo-rawicom.svg';
$timehero-logo-image-height: 31px;


$anwim-red-color: #cd0a0a;
$sidebar-bg-color: $timehero-color1;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-width: 260px !default;
$sidebar-color: white;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 24px;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$dark-dark-green: #08180e;
@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar-background {
    position: absolute;
    width: 250px;
    height: 100%;
    z-index: 300;
    border-top-right-radius: 20px;
    background: $timehero-side-background;
    box-shadow: 0px 4px 17px rgba(48, 89, 232, 0.57);
}

html {
    height: 100%;
}

* {
    font-family: Poppins !important;
}

.pi {
    font-family: 'primeicons' !important;
}

body {
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    a {
        color: $timehero-color3;
    }

 
    div#root {
        height: 100%;
        width: 100%;
        background-color: #f4f5fc;

        .CookieConsent {
            .cookie-consent-buttons {
                display: flex;

                a.p-button-text-only {
                    width: fit-content;
                }
            }
        }
    }

    .block-ui {
        position: relative;
        min-height: 3em;
    }

    .small-loader.block.block-ui-container {
        z-index: 99;
        position: absolute;
        margin-bottom: 0px;

        .block-ui-overlay {
            opacity: 1;
        }
    }

    .card-loader.block.block-ui-container {
        margin-top: -24px;
        margin-right: -24px;
        margin-left: -24px;
        margin-bottom: -24px;
        height: calc(100% + 48px);
    }

    .p-link.contract-terms-pdf span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
    }

    .block-ui-container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        min-height: 2em;
        cursor: wait;
        overflow: hidden;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        visibility: hidden;
        display: none;
        opacity: 0;
        transition: visibility 0.3s linear, opacity 0.3s linear;
        z-index: 2210;

        .block-ui-overlay {
            width: 100%;
            height: 100%;
            opacity: 0.5;
            filter: alpha(opacity=50);
            background-color: white;
        }

        .block-ui-message-container {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            text-align: center;
            transform: translateY(-50%);
            z-index: 10001;

            .block-ui-message {
                color: #333;
                background: none;
                z-index: 2211;

                /* CSS Animations */
                @-webkit-keyframes fadeInRTL1 {
                    0% {
                        opacity: 0;
                        -webkit-transform: translateX(20px);
                        transform: translateX(20px);
                    }

                    30% {
                        opacity: 1;
                        -webkit-transform: translateX(0px);
                        transform: translateX(0px);
                    }

                    60% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 0;
                    }
                }

                @keyframes fadeInRTL1 {
                    0% {
                        opacity: 0;
                        -webkit-transform: translateX(20px);
                        transform: translateX(20px);
                    }

                    30% {
                        opacity: 1;
                        -webkit-transform: translateX(0px);
                        transform: translateX(0px);
                    }

                    60% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 0;
                    }
                }

                @-webkit-keyframes fadeInRTL2 {
                    0% {
                        opacity: 0;
                    }

                    10% {
                        opacity: 0;
                        -webkit-transform: translateX(20px);
                        transform: translateX(20px);
                    }

                    40% {
                        opacity: 1;
                        -webkit-transform: translateX(0px);
                        transform: translateX(0px);
                    }

                    60% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 0;
                    }
                }

                @keyframes fadeInRTL2 {
                    0% {
                        opacity: 0;
                    }

                    10% {
                        opacity: 0;
                        -webkit-transform: translateX(20px);
                        transform: translateX(20px);
                    }

                    40% {
                        opacity: 1;
                        -webkit-transform: translateX(0px);
                        transform: translateX(0px);
                    }

                    60% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 0;
                    }
                }

                @-webkit-keyframes fadeInRTL3 {
                    0% {
                        opacity: 0;
                    }

                    20% {
                        opacity: 0;
                        -webkit-transform: translateX(20px);
                        transform: translateX(20px);
                    }

                    50% {
                        opacity: 1;
                        -webkit-transform: translateX(0px);
                        transform: translateX(0px);
                    }

                    60% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 0;
                    }
                }

                @keyframes fadeInRTL3 {
                    0% {
                        opacity: 0;
                    }

                    20% {
                        opacity: 0;
                        -webkit-transform: translateX(20px);
                        transform: translateX(20px);
                    }

                    50% {
                        opacity: 1;
                        -webkit-transform: translateX(0px);
                        transform: translateX(0px);
                    }

                    60% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 0;
                    }
                }

                .loading-bullet {
                    display: inline-block;
                    opacity: 0;
                    -webkit-animation: 3s ease 0.5s infinite fadeInRTL1;
                    animation: 3s ease 0.5s infinite fadeInRTL1;
                }

                .loading-bullet + .loading-bullet {
                    -webkit-animation-name: fadeInRTL2;
                    animation-name: fadeInRTL2;
                }

                .loading-bullet + .loading-bullet + .loading-bullet {
                    -webkit-animation-name: fadeInRTL3;
                    animation-name: fadeInRTL3;
                }

                #cover-spin-background {
                    position: fixed;
                    width: 100%;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(255, 255, 255, 0.7);
                    z-index: 29999;
                    display: none;
                }

                @-webkit-keyframes spin {
                    from {
                        -webkit-transform: rotate(0deg);
                    }

                    to {
                        -webkit-transform: rotate(360deg);
                    }
                }

                @keyframes spin {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(360deg);
                    }
                }

                #cover-spin-container {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                #cover-spin {
                    margin: auto;
                    width: 40px;
                }

                #cover-spin::after {
                    content: '';
                    display: block;
                    width: 40px;
                    height: 40px;
                    border-style: solid;
                    border-color: #1c3275;
                    border-top-color: transparent;
                    border-width: 4px;
                    border-radius: 50%;
                    -webkit-animation: spin 0.8s linear infinite;
                    animation: spin 0.8s linear infinite;
                }

                #cover-spin-text {
                    padding-top: 20px;
                    font-size: 13px;
                }
            }

            .block-ui-message.horizontal {
                #cover-spin-container {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .icon-large {
        font-size: 20px;
    }

    .icon-large-plus {
        font-size: 30px;
        margin: 10px;
    }

    .subcontractor-list {
        .icon {
            height: 320px;
            width: 320px;
            margin: 0;
        }
    }

    .subcontractor-dialog {
        width: 580px;
        height: auto;
        position: relative;
        background: white;

        @media (max-height: 700px) {
            height: 100%;
            max-height: 100%;
            border-radius: 0;
        }

        .p-dialog-content {
            padding: 0 0 30px 0;
            max-height: 480px;

            @media (max-height: 700px) {
                max-height: calc(100vh - 145px);
            }
        }
        @media (max-width: 580px) {
            width: 100%;
        }
    }

    .projectcategory-dialog {
        width: 580px;
        height: auto;
        position: relative;
        background: white;

        @media (max-height: 700px) {
            height: 100%;
            max-height: 100%;
            border-radius: 0;
        }

        .p-dialog-content {
            padding: 0 0 30px 0;
            max-height: 480px;

            @media (max-height: 700px) {
                max-height: calc(100vh - 145px);
            }
        }
        @media (max-width: 580px) {
            width: 100%;
        }
    }
    .activity-dialog {
        width: 580px;
        height: auto;
        position: relative;
        background: white;

        @media (max-height: 700px) {
            height: 100%;
            max-height: 100%;
            border-radius: 0;
        }

        .p-dialog-content {
            padding: 0 0 30px 0;
            max-height: 480px;

            @media (max-height: 700px) {
                max-height: calc(100vh - 145px);
            }
        }
        @media (max-width: 580px) {
            width: 100%;
        }
    }
    .car-dialog {
        width: 580px;
        height: auto;
        position: relative;
        background: white;

        @media (max-height: 700px) {
            height: 100%;
            max-height: 100%;
            border-radius: 0;
        }

        .p-dialog-content {
            padding: 0 0 30px 0;
            max-height: 480px;

            @media (max-height: 700px) {
                max-height: calc(100vh - 145px);
            }
        }
        @media (max-width: 580px) {
            width: 100%;
        }

        #odometer {
            input {
                text-align: right;
            }
        }
    }

    .block-ui-container.block {
        visibility: visible;
        display: block;
        opacity: 1;
    }

    .block-ui-container:focus {
        outline: none;
    }

    #custom-messages {
        width: 100%;
        background-color: transparent;
        z-index: 20000;
        position: fixed;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        top: 0;
        // position: absolute;

        .p-messages {
            .p-messages-close {
                top: 0;
                right: 0;
            }

            ul {
                padding-right: 10px;
            }
        }

        .p-message-summary {
            padding-right: 8px;
            font-weight: 600;
        }

        .p-messages.p-messages-error {
            white-space: pre;
            z-index: 1500;
        }
    }

    @media (min-width: 576px) {
        #custom-messages {
            width: unset;
        }
    }

    .app {
        height: 100%;
        display: flex;
        position: relative;
        background-color: white;


        .sidebar-horizontal {
            display: none;
            height: 74px;
            background: #769188;
            box-shadow: 0px 4px 17px rgba(48, 89, 232, 0.57);
            // padding: 16px;
            justify-content: space-between;

            .sidebar-horizontal-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 16px;
                height: 100%;

                .sidebar-button {
                    height: 32px;
                    max-width: fit-content;
                    padding: 0;
                    background: transparent;

                    .icon-svg {
                        height: 32px;
                        width: 32px;
                        margin: 0;
                    }

                    .icon-svg.menu {
                        margin-right: 8px;
                        background: $timehero-light-color1;
                        border-radius: 16px;
                    }

                    .icon-svg.menu-close {
                        margin-right: 8px;
                        border-radius: 16px;
                        border: 1px solid $timehero-light-color1;
                    }

                    .icon-svg.profile {
                        border-radius: 16px;
                        background: $timehero-light-color1;
                        padding: 4px;
                    }
                }

                .profile {
                    border-radius: 16px;
                }

                .menu {
                    border-bottom-left-radius: 16px;
                    border-top-left-radius: 16px;
                }

                .menu-close {
                    border-bottom-left-radius: 16px;
                    border-top-left-radius: 16px;
                    color: transparent;
                }

                .sidebar-show .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout {
                    .pro-sidebar-header {
                        padding: 16px 0 0 16px;

                        .p-button {
                            padding: 0;
                            width: fit-content;
                            height: fit-content;
                        }
                    }
                }
            }
        }

        .sidebar {
            display: none;
        }

        .pro-sidebar {
            position: absolute;
            z-index: 300;
            background: transparent;

            ::-webkit-scrollbar {
                height: 10px;
                width: 10px;
                background: rgba(0, 0, 0, 0);
            }

            > .pro-sidebar-inner {
                // position: fixed;
                background: transparent;

                .pro-sidebar-layout {
                    overflow: overlay;
                    background: transparent;

                    .pro-sidebar-header {
                        background: transparent;
                        border-bottom: none;
                        display: flex;
                        flex-direction: column;
                        padding-top: 10px;
                        text-align: center;

                        .sidebar-version {
                            text-align: center;
                            height: 12px;
                            color: white;
                            margin-bottom: 10px;
                        }

                        .sidebar-header-logo-div {
                            padding-right: 43px;
                            padding-left: 20px;
                            padding-bottom: 23px;
                            margin-bottom: 22px;

                            background-image: url($timehero-logo-image);
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            max-height: $timehero-logo-image-height;
                            height: $timehero-logo-image-height;
                            //width: auto;
                            //max-width: 200px;

                            display: block;
                            margin-top: 25px;

                            // .sidebar-header-logo {
                            //     max-height: $timehero-logo-image-height;
                            //     max-width: 200px;
                            //     margin-top: 25px;


                            // }
                        }
                    }

                    .pro-sidebar-content {
                        background: transparent;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .pro-menu {
                            background: transparent;
                            padding-top: 5px;
                            padding-bottom: 5px;

                            .pro-menu-item > {
                                .pro-inner-item.active {
                                    display: none;
                                    padding: 10px 10px 10px 26px;
                                    background-color: $timehero-light-color1;
                                    box-shadow: 0px 4px 4px rgba(23, 28, 115, 0.1);
                                    border-radius: 8px;
                                    margin-left: 8px;

                                    > .pro-item-content {
                                        opacity: 1;
                                    }

                                    > .pro-icon-wrapper {
                                        opacity: 1;
                                    }
                                }

                                .pro-inner-item {
                                    background: transparent;
                                    padding: 10px 10px 10px 34px;

                                    > .pro-item-content {
                                        background: transparent;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: break-spaces;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        letter-spacing: -0.01em;
                                        color: #f4f5fc;
                                        opacity: 1;
                                    }

                                    > .pro-icon-wrapper {
                                        display: inline-block;
                                        color: #f4f5fc;
                                        background: transparent;
                                        font-size: 25px;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }

                @media (max-width: 1024px) {
                    .pro-sidebar-layout {
                        height: calc(100% - 64px);
                    }
                }
            }
        }

        .pro-sidebar.collapsed {
            z-index: 300;

            > .pro-sidebar-inner {
                .pro-sidebar-layout {
                    .pro-sidebar-header {
                        .sidebar-header-logo {
                            width: 0px;
                            max-height: 44px;
                        }
                    }

                    .pro-menu {
                        .pro-menu-item > .pro-inner-item > .pro-item-content {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        main > div.container-fluid {
            padding-left: 260px;
        }

        main > div.container-fluid {
            .container-fluid {
                padding-left: 15px;
            }
        }

        main {
            padding: 0px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: clip;

            .phone-number-row {
                margin-left: -12px;
                margin-right: -12px;
            }

            

            .backlink {
                .p-link {
                    color: $timehero-color2;
                }
            }

           
            .header-white-background {
                position: relative;
                // min-height: 232px;
                left: 0px;
                right: 0px;
                top: 0px;
                z-index: 0;
                background-color: #ffffff;
            }

            > .container-fluid {
                //   z-index: 1;
                padding: 0px 40px;

                // margin-top: -232px;
                .p-banner {
                    position: relative;
                    min-height: 64px;
                    margin-bottom: 30px;
                    //  margin-left: 32px;
                }

                .header-banner {
                    .p-banner {
                        position: absolute;
                        top: 0px;
                    }
                }
            }

            .header-white-background.default-white-background {
                min-height: 293px;
            }
                       

            .container-fluid.default-white-background {
                margin-top: -293px;
                height: 100%;
            }



            header {
                height: 100px;
                // margin-bottom: 40px;
                display: inline-block;

                .session-div {
                    padding-top: 22px;
                    width: 100%;
                    height: 38px;
                    display: inline-flex;
                    justify-content: flex-end;

                    .session-label {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                        color: #8e92a3;
                    }
                }

                .header-content {
                    display: inline-flex;

                    @media (max-width: 720px) {
                        display: inline-flex;
                    }

                    align-items: baseline;
                    width: 100%;
                    padding-bottom: 30px;

                    .header-welcome {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 22px;
                        line-height: 32px;
                        flex-basis: 1;
                        flex-grow: 1;
                        margin-left: 12px;
                    }

                    .header-welcome.header-welcome-label {
                        font-weight: 700;
                    }

                    .header-welcome-link {
                        flex-grow: 1;
                    }

                    .header-links {
                        display: inline-flex;

                        @media (max-width: 1023px) {
                            display: none;
                        }

                        flex-basis: auto;
                        flex-grow: 0;

                        .header-link {
                            cursor: pointer;
                            padding-top: 8px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: #444757;
                            text-decoration: none;
                            margin-right: 32px;
                        }

                        .header-link.dropdown {
                            display: flex;
                        }

                        .header-link:last-child {
                            margin-right: 0;
                        }

                        .p-dropdown {
                            display: flex;
                            background-color: transparent;
                            border: none;
                            line-height: 20px;
                            height: auto;
                            padding: 0;

                            .p-dropdown-trigger {
                                justify-content: right;
                                height: 20px;
                                width: 18px;
                            }

                            .p-dropdown-label {
                                padding: 0;
                                color: #444757;
                                font-weight: 500;
                                line-height: 20px;
                            }
                        }

                        .dropdown-label {
                            white-space: break-spaces;
                        }
                    }

                    .notification-list {
                        margin-right: 25px;

                        .notification-icon {
                            position: relative;
                            display: inline;
                            cursor: pointer;
                        }

                        .notification-counter {
                            position: absolute;
                            right: 0;
                            background-color: $timehero-color1;
                            font-size: 11px;
                            color: white;
                            display: inline;
                            padding: 3px 3px;
                            border-radius: 20px;
                        }

                        .notification-list-scrollable-content::-webkit-scrollbar {
                            line-height: 12px;
                            height: 12px;
                            width: 18px;
                        }

                        .notification-list-scrollable-content::-webkit-scrollbar-button {
                            display: none;
                        }

                        .notification-list-scrollable-content::-webkit-scrollbar-thumb {
                            background-color: #e4e6f0;
                            height: 8px;
                            border-radius: 12px;
                            border: 6px solid white;
                        }

                        .notification-list-scrollable-content::-webkit-scrollbar-track {
                            background-color: transparent;
                            border-radius: 12px;
                        }

                        .notification-list-scrollable-content::-webkit-scrollbar-track-piece {
                            background-color: transparent;
                            border-radius: 12px;
                        }

                        .notification-list-content {
                            @media (max-width: 1023px) {
                                margin-left: -265px;
                                margin-top: 44px;
                            }

                            position: absolute;
                            z-index: 1001;
                            background-color: white;
                            border: 1px solid #e4e6f0;
                            border-radius: 10px;
                            padding: 8px 0px 8px 0px;
                            max-width: 300px;
                            margin-left: -60px;
                            margin-top: 44px;
                            max-height: 250px;
                            min-height: 100px;
                            display: inline-flex;
                            flex-direction: column;

                            .notification-list-content-triangle {
                                @media (max-width: 1023px) {
                                    margin-left: 238px;
                                    margin-top: -24px;
                                }

                                z-index: 1002;
                                position: relative;
                                margin-left: 34px;
                                margin-top: -24px;
                                width: 0;
                                height: 0;
                                border-left: 10px solid transparent;
                                border-right: 10px solid transparent;
                                border-bottom: 16px solid #e4e6f0;

                                .notification-list-content-triangle-inner {
                                    position: relative;
                                    top: 1px;
                                    right: 9px;
                                    width: 0;
                                    height: 0;
                                    border-left: 9px solid transparent;
                                    border-bottom: 15px solid white;
                                    border-right: 9px solid transparent;
                                }
                            }

                            .notification-clear-button {
                                margin-top: 4px;
                            }

                            .notification-list-scrollable-content {
                                overflow-y: auto;
                                padding-left: 8px;

                                .notification-list-item:hover {
                                    background-color: #e4e6f0;
                                    cursor: pointer;
                                }

                                .notification-list-item {
                                    display: inline-flex;
                                    flex-direction: column;
                                    align-content: space-around;
                                    align-items: flex-start;
                                    width: 100%;
                                    border-radius: 6px;
                                    padding: 4px;

                                    .notification-list-item-title.error {
                                        color: #cd0a0a;
                                    }

                                    .notification-list-item-title {
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 20px;
                                        min-height: 20px;
                                        color: #090e22;
                                    }

                                    .notification-list-item-content {
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 12px;
                                        line-height: 16px;
                                        min-height: 16px;
                                        letter-spacing: -0.01em;
                                        color: #8e92a3;
                                    }

                                    .notification-list-item-progress {
                                        .p-progressbar-label {
                                            display: none;
                                        }

                                        margin-top: 4px;
                                        width: 100%;
                                        height: 6px;
                                        border-radius: 6px;
                                    }
                                }
                            }
                        }

                        .notification-list-content.hidden {
                            display: none;
                        }
                    }
                }

                h1 {
                    display: flex;
                    align-items: center;
                    transform: translateX(-20px);
                }
            }

            .container-fluid {
                .header-dialog-button-container {
                    display: flex;
                    margin-bottom: 42px;

                    .p-button {
                        min-width: min-content;
                        width: fit-content;
                    }

                    .left-footer-dialog-button-container {
                        flex: 1 1 auto;
                        margin-left: 16px;

                        .p-button {
                            min-width: min-content;
                            width: fit-content;
                            margin-left: 16px;
                        }
                    }
                }

                @media (max-width: 400px) {
                    .header-dialog-button-container {
                        flex-wrap: wrap;

                        .p-button {
                            text-align: center;
                            width: 100%;
                            margin-left: 0px;
                            margin-bottom: 10px;

                            span {
                                width: 100%;
                            }
                        }

                        .p-button:last-child {
                            margin-bottom: 0px;
                        }

                        .left-header-dialog-button-container {
                            width: 100%;
                            margin-left: 0px;
                            margin-bottom: 10px;

                            .p-button {
                                text-align: center;
                                width: 100%;
                                margin-left: 0px;
                                margin-bottom: 10px;
                            }

                            .p-button:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }

                .user-details-header {
                    margin-bottom: 50px;

                    .row.back-link {
                        margin-left: 0px;
                        margin-right: 0px;
                        padding-bottom: 33px;
                    }

                    @media (max-width: 767px) {
                        .card-photo-container {
                            display: none;
                        }
                    }

                    .card-photo-container {
                        width: 140px;
                        height: 100px;
                        margin-left: 40px;

                        .profile-big-icon {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .card-photo-container {
                        width: auto;

                        .header-card-image {
                            width: 156px;
                            height: 100px;
                        }
                    }

                    .card-info-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .card-info-label {
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 32px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #090e22;
                        }

                        .p-inputtext-view {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0px;
                            text-align: left;
                        }

                        .icon-svg {
                            margin-left: 10px;
                        }

                        .row {
                            > .col-width-auto {
                                @media (max-width: 675px) {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .button-column {
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: column;

                        .lock-card-button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 150px;
                            height: 48px;
                            align-self: end;
                        }

                        .renew-card-button {
                            margin-top: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 150px;
                            height: 48px;
                            align-self: end;
                        }

                        @media (max-width: 1470px) {
                            flex-direction: column;
                            align-content: flex-end;

                            .renew-card-button {
                                margin-left: 0px;
                                margin-top: 8px;
                            }
                        }

                        @media (max-width: 675px) {
                            width: 100%;
                            align-content: unset;
                            flex-direction: row;

                            .renew-card-button {
                                margin-left: 8px;
                                margin-top: 0px;
                            }
                        }

                        @media (max-width: 478px) {
                            padding-top: 24px;
                            justify-content: center;
                            flex-direction: column;

                            .lock-card-button {
                                width: 100%;
                            }

                            .renew-card-button {
                                width: 100%;
                                margin-top: 8px;
                                margin-left: 0px;
                            }
                        }
                    }
                }
            }

            
            footer {
                width: 100%;
                margin-top: 5px;
                text-align: center;
                opacity: 0.95;
                height: 60px;
            }

            .list-title {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #090e22;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }

            .p-card.list-card,
            .p-card.details-card {
                background: transparent;
                box-shadow: none;
            }

            .p-card.list-card > .p-card-body,
            .p-card.details-card > .p-card-body {
                padding: 0px;
            }

            .p-card.list-card > .p-card-body > .p-card-content,
            .p-card.details-card > .p-card-body > .p-card-content {
                padding: 0px;
            }

            .details-main {
                .details-main-link-container {
                    > .row {
                        --bs-gutter-y: 24px;
                        padding-bottom: 0px;
                    }

                    padding-bottom: 0px;
                    padding-top: 20px;

                    .p-datatable-tfoot {
                        border: none;
                        font-size: 12px;
                    }

                    .p-label-bold {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 14px;
                        color: #8e92a3;
                    }

                    .p-card {
                        box-shadow: none;
                        background: #ffffff;
                        border: 1px solid #e4e6f0;
                        box-sizing: border-box;
                        border-radius: 8px;
                        display: flex;
                        flex-direction: column;

                        //height: 100%;
                        margin-bottom: 30px;

                        .p-card-header {
                            .row {
                                --bs-gutter-x: 0;
                            }
                            font-weight: 700;
                            padding-top: 10px;
                            padding-left: 10px;
                        }

                        .p-card-content {
                            padding: 0px;
                            .row {
                                min-height: 10px;
                                white-space: wrap;
                                word-wrap: break-word;
                                .custStyle {
                                    width: 200px;
                                    word-wrap: break-word;
                                }
                                .form-group.radiobutton {
                                    padding-top: 4px;
                                    padding-bottom: 4px;
                                }

                                .form-group.checkbox {
                                    padding-top: 4px;
                                    padding-bottom: 4px;
                                }
                            }
                        }

                        .p-card-body {
                            padding: 24px;
                            .p-card-content {
                                padding: 0;

                                > .row.required-data {
                                    --bs-gutter-y: 8px;

                                    .col-width-auto {
                                        @media (max-width: 684px) {
                                            width: 50%;
                                        }

                                        @media (max-width: 400px) {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .container-fluid.start-page {
                .p-banner {
                    margin-left: 0px;
                    margin-right: 0px;
                }

                .banner-offer-for-you {
                    height: unset;
                    overflow: auto;

                    .p-card-body {
                        padding: 0;

                        .p-card-content {
                            padding: 0;

                            > div {
                                position: relative;

                                > img {
                                    width: 100%;
                                }

                                > span {
                                    position: absolute;
                                    left: 16px;
                                    bottom: 84px;
                                    color: white;
                                    font-weight: 700;
                                }

                                > .p-button {
                                    display: flex;
                                    padding: 0;
                                    justify-content: center;
                                    align-items: center;
                                    position: absolute;
                                    width: 175px;
                                    height: 48px;
                                    left: 16px;
                                    bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }

        }

        .block {
            display: flex;
            margin-bottom: 24px;
            font-size: 14px;
            color: #545454;

            > span {
                margin-left: 10px;
            }
        }

        .range-dash {
            display: block;
            padding: 0px;
            height: 2px;
            background: #090e22;
            width: 6px;
            margin-top: 24px;
            margin-right: -4px;
            margin-left: -4px;
        }

        .p-card-content {
            .p-card-header-minheight {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                position: relative;
                padding-bottom: 28px;

                .header-item {
                    width: unset;
                }

                .header-item-right {
                    display: flex;
                    right: 0px;
                    width: unset;
                    position: absolute;
                    padding-bottom: 28px;
                    align-items: center;

                    *:not(:first-child) {
                        margin-left: 18px;
                    }
                }

                a.download-header-link {
                    color: $timehero-color3; 
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    text-decoration: none;
                }
            }

            .p-card-header-minheight.back-off {
                justify-content: unset;
                margin-left: 12px;
                margin-top: 16px;

                a.p-button-text-only.header-item {
                    padding: 17px 32px 17px 33px;
                }
            }
        }
    }

    .p-label {
        // font-style: normal;
        // font-weight: 300;
        // font-size: 16px;
        // line-height: 170.1%;
        // color: #313131;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        min-height: 16px;
        letter-spacing: -0.01em;
        color: #8e92a3;
        margin-bottom: 6px;
    }

    .p-steps {
        background-color: transparent;
        border: 1px solid #d5d7de;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 16px;

        .p-steps-item {
            .p-menuitem-link {
                background-color: transparent;

                .p-steps-number {
                    color: $timehero-light-color1;
                    background-color: transparent;
                    border: none;
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                }

                .p-steps-title {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;
                    color: #090e22;
                }
            }
        }

        .p-steps-current {
            .p-menuitem-link {
                .p-steps-title {
                    color: #3059e8;
                    font-weight: 600;
                }
            }
        }

        li.p-steps-current ~ li {
            .p-menuitem-link {
                .p-steps-title {
                    color: #737786;
                }
            }
        }

        .p-disabled {
            opacity: 1;
        }

        .p-steps-item {
            flex: 1;
        }

        .p-steps-item:first-child:before {
            left: 50%;
            width: 50%;
        }

        .p-steps-item:last-child:before {
            width: 50%;
        }

        .p-steps-item:before {
            border-top: 2px solid $timehero-light-color1;
        }
    }

    .p-checkbox-label.p-label {
        max-width: calc(100% - 24px);
        color: #090e22;
        font-size: 14px;
        line-height: 20px;
        padding-left: 12px;
    }

    label.p-checkbox-label.p-label.gray-description {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: #8e92a3;
    }

    label.p-checkbox-label.disabled-label {
        color: #8e92a3;
    }

    .gray-border {
        border-radius: 3px;
        border: 1px solid #cfd2dc;
    }

    .transparent-border {
        border-radius: 3px;
        border: 1px solid transparent;
    }

    .p-inputtext {
        border: 1px solid #e4e6f0;
        box-sizing: border-box;
        border-radius: 8px;
        background: #f4f5fc;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #090e22;
    }

    .p-inputtext-view {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: black;
        display: flex;
        align-items: center;
    }

    .p-inputswitch {
        .p-inputswitch-slider:before {
            left: unset;
            margin-left: 3px;
            width: 18px;
            height: 18px;
            margin-top: -9px;
        }

        .p-inputswitch-slider {
            height: 24px;
            width: 40px;
            left: 0px;
            top: 0px;
            border-radius: 66px;
        }

        .p-inputswitch-checked {
            height: 24px;
            width: 40px;
            left: 0px;
            top: 0px;
            border-radius: 66px;
        }
    }

    .p-inputswitch.p-inputswitch-checked {
        .p-inputswitch-slider:before {
            transform: translateX(16px);
        }

        .p-inputswitch-slider {
            background-color: $timehero-light-color1;
        }
    }

    .p-password {
        .p-password-input {
            width: 100%;
        }
    }

    .p-inputswitch-label.p-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #444757;
    }

    span {
        white-space: pre-line;
    }

    .pdf-download-button {
        width: auto !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        display: inline-block !important;
        padding: 13px !important;
        background-color: $timehero-light-color1 !important ;
        margin-right: 7px;
    }

    .dashboard-paginator {
        width: auto !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        display: inline-block !important;
        padding: 7px !important;
        // background-color: $timehero-light-color1!important ;
        background-color: rgba(255, 255, 255, 0) !important ;
        height: 40px;

        .button {
            color: $timehero-light-color1 !important ;
            max-height: 30px;
            .p-paginator-pages {
                height: 40px;
            }
        }
        .p-paginator-element {
            color: $timehero-light-color1 !important ;
            max-height: 20px;
        }
    }

    a.p-button-text-only {
        height: 40px;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        background: $timehero-light-color1;
        border: none;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(23, 28, 115, 0.03);
        border-radius: 8px;
        color: #ffffff;
        text-align: center;
        text-decoration: none;
        padding: 13px 32px;

        span {
            white-space: nowrap;
        }
    }

    a.p-button-text-only.big {
        height: 50px;
    }

    a.p-button-text-only.header-item {
        width: unset;
        padding-top: 15px;
        padding-bottom: 15px;

        .p-button-text.p-c {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    a.p-button-text-only.white:not(.p-disabled),
    a.p-button-text-only.white {
        background: #ffffff;
        color: $timehero-light-color1;
        border: 1px solid #e4e6f0;
    }

    a.p-button-text-only.white-pressed {
        background: #ffffff;
        color: $timehero-light-color1;
        outline: 2px solid $timehero-light-color1;
        border: 1px solid #e4e6f0;
    }

    a.p-button-text-only.white-black {
        background: #ffffff;
        color: #000000;
        border: 1px solid #e4e6f0;
    }

    a.p-button-text-only.red:not(.p-disabled),
    a.p-button-text-only.red {
        background: #ffffff;
        color: #dc043e;
        border: 1px solid #e4e6f0;
    }

    a.p-button-text-only.blue {
        background: $timehero-light-color1;
        color: #ffffff;
    }

    a.p-button-text-only.fullred {
        background: #dc043e;
        color: #ffffff;
    }

    a.p-button-text-only.height-88 {
        height: 88px;
    }

    a.edit-blue-link {
        color: $timehero-light-color1;
        text-decoration: unset;
        font-weight: 600;
        font-style: normal;
    }

    a.back-blue-link {
        color: $timehero-light-color1;
        text-decoration: unset;
        font-weight: 600;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.01em;
        text-align: left;

        .left-arrow-style {
            padding-right: 10px;
        }
    }

    .label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #8e92a3;
        margin-bottom: 6px;
    }

    .icon-svg {
        width: 16px;
        height: 16px;
        margin-top: 2px;
        margin-bottom: 4px;
    }

    .icon-svg.l {
        width: 56px;
        height: 56px;
        margin-top: 3px;
        margin-bottom: 3px;
        margin-right: 20px;
        margin-left: -16px;
    }

    .icon-svg.left {
        margin-right: 8px;
    }

    .icon-svg.right {
        margin-left: 8px;
    }

    //todo
    .icon-svg.right-label {
        margin: 0px;
        margin-bottom: 6px;
        padding: 0px;
    }

    .icon-svg.left-label {
        margin-right: 0px;
        margin-bottom: 6px;
    }

    .p-label.right-label {
        padding-left: 0px;
    }

    .icon-svg.right-label.red-star {
        margin-top: -4px;
    }

    .icon-svg.left-label.red-star {
        margin-top: -4px;
    }

    .white-filter .icon-svg {
        color: white;
        filter: brightness(0) invert(1);
    }

    .p-accordion.filter-accordion {
        margin-bottom: 42px;
    }

    .p-accordion {
        background: transparent;
        font-family: Poppins;

        .p-accordion-header {
            background: white;
            margin-top: 4px;
            border-radius: 8px;

            .p-accordion-header-link {
                border-radius: 8px;
                border: 1px solid #e4e6f0;
                background: transparent;
                padding: 12px 16px;

                .p-accordion-toggle-icon {
                    position: absolute;
                    right: 24px;
                    margin: 0px;
                    color: $timehero-light-color1;
                    font-size: 10px;
                    font-weight: 900;
                }

                .p-accordion-header-text {
                    display: inline-flex;
                    align-items: center;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;

                    .card-icon {
                        padding: 6px;
                        height: 32px;
                        width: 32px;
                        border-radius: 100%;
                        background: #f4f5fc;
                        margin-right: 8px;
                    }
                }
            }
        }

        .p-accordion-header.p-highlight {
            .p-accordion-header-link {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }

        .p-accordion-header.p-highlight:not(.p-disabled) {
            .p-accordion-header-link {
                background: white;
            }
        }

        .p-accordion-content {
            border: 1px solid #e4e6f0;
            padding: 24px;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            .form-search-criteria {
                margin-bottom: 0px;

                .p-dropdown {
                    .p-dropdown-label {
                        padding-top: 14px;
                        padding-bottom: 14px;
                    }
                }

                .p-multiselect {
                    .p-multiselect-label-container {
                        line-height: 32px;
                    }
                }

                .p-dropdown {
                    .p-dropdown-label {
                        padding: 8px 0 8px 12px;
                        line-height: 32px;
                    }
                }
            }

            .form-search-criteria > div[class*='col-'] > .row {
                align-items: flex-end;
            }
        }

        .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
            border-top: 1px solid #dee2e6;
        }

        .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
        }

        .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
        }

        .p-accordion-tab .p-accordion-content {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }

    .p-calendar {
        .p-inputtext {
            border-right: none;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
        }

        .p-datepicker-trigger {
            padding: 16px 8px;
            background-color: #f4f5fc;
            border: 1px solid #e4e6f0;
            border-left: none;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;

            .p-button-icon.pi-calendar {
                background-color: transparent;
                background-image: url('../img/ico_calendar.svg');
                background-repeat: no-repeat;
                background-size: cover;
                width: 16px;
                height: 16px;
            }

            .pi-calendar:before {
                content: unset;
            }

            .p-button-label {
                height: 16px;
            }
        }

        .p-datepicker-trigger:hover {
            .p-button-icon.pi-calendar {
                background-image: url('../img/ico_calendar-white.svg');
            }
        }
    }

    .p-calendar:not(.p-disabled):hover {
        .p-inputtext {           
           border-color: $timehero-color4;
        }
        .p-datepicker-trigger {
            border-color: $timehero-color4;
        }
        .p-datepicker-trigger {
            background-color: $timehero-color4;
            .p-button-icon.pi-calendar {
                background-image: url('../img/ico_calendar-white.svg');                
            }
        }        
    }

    .p-button.dialog-button {
        height: 35px;
    }

    .srv-validation-message {
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-align: left;
    }

    .p-inputtext {
        border: 1px solid #e4e6f0;
        box-sizing: border-box;
        background: $timehero-input-background;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #090e22;
        padding: 14px 11px 14px 13px;
    }

    .p-radiobutton-box {
        color: #aeb1be;
    }

    .p-radiobutton.p-radiobutton-checked {
        .p-radiobutton-box.p-highlight {
            border-color: $timehero-light-color1;
            background: $timehero-light-color1;
        }
    }

    .p-component {
        .p-checkbox-label.p-label {
            padding-left: 8px;
            max-width: calc(100% - 24px);
            color: #090e22;
            font-size: 14px;
            line-height: 20px;
            padding-left: 12px;
            //padding: 0.5rem;
        }

        .p-checkbox {
            width: 24px;
            height: 24px;

            .p-checkbox-box {
                width: 24px;
                height: 24px;
            }
        }
    }

    .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
        box-shadow: none;
    }

    .p-dropdown {
        border: 1px solid #e4e6f0;
        box-sizing: border-box;
        border-radius: 8px;
        background: $timehero-input-background;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #090e22;

        .p-inputtext {
            border: none;
        }

        .p-dropdown-label {
            padding: 8px 0 8px 12px;
            line-height: 32px;
        }

        .p-dropdown-trigger {
            width: 32px;

            .p-dropdown-trigger-icon {
                font-size: 10px;
                font-weight: 900;
                color: #090e22;
            }
        }
    }

    .p-radiobutton-box {
        color: #aeb1be;
    }

    .p-radiobutton {
        .p-radiobutton-checked {
            .p-radiobutton-box.p-highlight {
                border-color: $timehero-light-color1;
                background: $timehero-light-color1;
            }
        }
    }

    .p-multiselect {
        border: 1px solid #e4e6f0;
        box-sizing: border-box;
        border-radius: 8px;
        background: #f4f5fc;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #090e22;
    }

    .p-datatable-responsive {
        tr {
            .number-value {
                text-align: end;

                .product-label {
                    margin-right: 0;
                    margin-left: auto;
                }
            }

            .date {
                white-space: pre;
            }
        }

        th.number-value {
            .p-column-header-content {
                justify-content: flex-end;
            }
        }

        ::-webkit-scrollbar {
            line-height: 12px;
            height: 12px;
        }

        ::-webkit-scrollbar-button {
            display: none;
        }

        ::-webkit-scrollbar-thumb {
            background-color: white;
            height: 8px;
            border-radius: 12px;
            border: 4px solid black;
        }

        ::-webkit-scrollbar-track {
            background-color: black;
            border-radius: 12px;
        }

        ::-webkit-scrollbar-track-piece {
            background-color: black;
            border-radius: 12px;
        }

        ::-webkit-scrollbar-track-piece:end {
            margin-right: 28px;
        }

        ::-webkit-scrollbar-track-piece:start {
            margin-left: 28px;
        }

        // ::-webkit-scrollbar-corner the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet.
        // ::-webkit-resizer the draggable resizing handle that appears at the bottom corner of some elements.
    }

    .p-datatable {
        border-collapse: separate;

        .p-datatable-tfoot tr > td {
            padding: 1rem;
            font-size: 12px;
            font-weight: 600;
            color: #090e22;
        }

        .p-datatable-wrapper {
            overflow: auto;

            .p-datatable-table {
                min-width: calc(100% - 1px);
                width: calc(100% - 1px);
                table-layout: auto;
            }
            .p-datatable-tfoot {
                tr {
                    height: 50px;
                    td {
                        background-color: white;
                    }
                    // background-color: white !important;
                }
            }
            .p-datatable-thead {
                tr {
                    th {
                        background: transparent;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 14px;
                        color: #090e22;
                    }

                    th.p-sortable-column {
                        color: #090e22;
                    }

                    th.p-sortable-column.p-highlight {
                        color: #090e22;
                    }

                    th.p-selection-column {
                        .p-checkbox-box.p-highlight {
                            background-color: $timehero-light-color1;
                            border: none;
                        }

                        //todo focus
                    }
                }
            }

            .p-datatable-tbody {
                tr {
                    padding: 5px;
                    // border-bottom: 2px solid #f4f5fc;
                    border: 2px solid #f4f5fc;
                    border-radius: 8px;
                    margin-top: 1px;
                    margin-bottom: 1px;
                    color: #090e22;
                    
                    td {
                        background: #ffffff;
                        border: 2px solid #f4f5fc;
                        border: none;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                        color: #090e22;

                        .p-chip-text {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 10px;
                        }
                        .p-chip {
                            margin-left: 3px;
                            margin-right: 3px;
                            margin-bottom: 3px;
                        }
                    }

                    td:first-child {
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }

                    td:last-child {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }

                    td.p-selection-column {
                        .p-checkbox-box.p-highlight {
                            background-color: $timehero-light-color1;
                            border: none;
                        }

                        //todo focus
                    }
                }
            }

            .p-checkbox {
                width: 24px;
                height: 24px;

                .p-checkbox-box {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .p-paginator {
            background: transparent;
            border: none;
            padding: 36px 0 0 0;
            align-items: flex-start;

            .p-paginator-rows-per-page {
                flex: 1;

                .p-paginator-rows-per-page-label {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.01em;
                    color: #8e92a3;
                }

                .p-paginator-rows-per-current {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.01em;
                    color: #8e92a3;
                    margin-left: 20px;
                }

                .p-dropdown {
                    .p-dropdown-label {
                        line-height: 20px;
                    }
                }
            }

            .p-paginator-page {
                margin-top: 0;
            }

            .p-paginator-dummy {
                flex: 1;
            }

            .p-dropdown {
                margin: 0 8px 0 0;
                background: white;

                .p-dropdown-label {
                    background: white;
                }
            }
        }

        .card-icon {
            cursor: pointer;
        }
    }

    .p-tabview {
        .p-tabview-nav-container {
            .p-tabview-nav-content {
                .p-tabview-nav {
                    background: transparent;
                    padding-bottom: 0px;
                    margin-bottom: 42px;
                    border-bottom: #e4e6f0 solid 1px;
                    width: max-content;

                    .p-unselectable-text {
                        margin-left: 32px;

                        .p-tabview-nav-link {
                            background: transparent;
                            padding: 12px 0px;
                            border: none;

                            .p-tabview-title {
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }

                    .p-unselectable-text:first-child {
                        margin-left: 0px;
                    }

                    .p-unselectable-text.p-tabview-selected.p-highlight {
                        .p-tabview-nav-link {
                            border: #2196f3 solid;
                            border-width: 0 0 2px 0;

                            .p-tabview-title {
                                color: $timehero-color3;
                            }
                        }
                    }
                }
            }
        }

        .p-tabview-panels {
            background: transparent;
            padding: 0px;
        }
    }

    .p-tabview.sub-tabview {
        .p-tabview-nav-container {
            .p-tabview-nav-content {
                .p-tabview-nav {
                    .p-unselectable-text.p-tabview-selected.p-highlight {
                        .p-tabview-nav-link {
                            .p-tabview-title {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
    }

    a.p-tabview-nav-link {
        padding: 10px 16px;
    }

    ul.p-tabview-nav {
        border: none;
        padding-bottom: 32px;
    }

    .card-list-manage-dialog {
        .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav {
            margin-bottom: 16px;
        }
    }

    .p-tabview-panels {
        padding-top: 0px;
    }

    a.p-tabview-nav-link:focus {
        box-shadow: none;
    }

    @media (max-width: 767.98px) {
        overflow-x: hidden;

        header {
            h1 {
                font-size: 24px;
            }
        }
    }

    @media (max-width: $breakpoint-md) {
        .btn-toggle {
            display: flex;
        }
    }

    .p-radiobutton-box {
        color: #aeb1be;
    }

    .p-radiobutton {
        vertical-align: middle;

        .p-radiobutton-checked {
            .p-radiobutton-box.p-highlight {
                border-color: $timehero-light-color1;
                background: $timehero-light-color1;
            }

            //todo style labela do radiobutona
        }
    }

    .stations-map-criteria-dialog {
        width: 480px;
        height: 632px;
        position: relative;
        background: white;

        @media (max-height: 700px) {
            height: 100%;
            max-height: 100%;
            border-radius: 0;
        }

        .p-dialog-header {
            .p-dialog-title {
                font-size: 16px;
                line-height: 20px;
            }
        }

        .p-dialog-content {
            padding: 0 0 30px 0;
            max-height: 480px;

            @media (max-height: 700px) {
                max-height: calc(100vh - 145px);
            }

            .stations-map-dialog-buttons {
                position: absolute;
                bottom: 30px;
                right: 24px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .clear-filter-link {
                    font-size: 12px;
                    line-height: 16px;
                    text-decoration: none;
                    font-weight: 500;
                    margin-right: 16px;
                }

                .button-submit {
                    display: flex;
                    justify-content: center;
                    padding: 13px 20px 13px 20px;
                }

                .p-button {
                    width: 136px;
                }
            }

            .p-dialog-header {
                border: none;
            }

            .card {
                border: none;
            }

            .p-card {
                border-radius: 8px;
                border: none;
                box-shadow: none;

                .row {
                    --bs-gutter-x: 0;
                }

                .filter-header {
                    display: none;
                }

                .p-accordion {
                    border-radius: 0;
                    border: none;
                    padding: 0;

                    .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
                        border-radius: 0;
                    }

                    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
                        color: $timehero-light-color1;
                        background: white;
                    }

                    .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
                        position: sticky;
                        margin-left: 8px;
                    }

                    .p-accordion-header-link {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: left;
                        padding: 20px 24px 20px 24px;
                        color: #8e92a3;

                        .p-accordion-header-text {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }

                    .p-accordion-content {
                        border: 0;
                        padding: 0 24px 24px 24px;
                    }

                    .p-accordion-header-link {
                        border: none;
                        height: 57px;
                    }

                    .p-accordion-header {
                        margin: auto;
                    }

                    .p-accordion-tab {
                        border-top: 1px solid #e4e6f0;
                    }

                    .p-accordion-tab:last-child {
                        border-bottom: 1px solid #e4e6f0;
                    }

                    .row div#fuelList {
                        .p-checkbox-label.p-label {
                            color: white;
                            border-radius: 3px;
                            padding: 4px;
                            margin-right: 2px;
                            width: auto;
                            line-height: 16px;
                        }
                    }

                    .form-group {
                        .p-checkbox-label {
                            font-size: 12px;
                            line-height: 16px;
                        }

                        padding: 0;

                        #stationTypeList {
                            div[class*='col-'] {
                                min-height: 40px;
                                margin-bottom: 0;
                            }

                            .p-checkbox-label {
                                padding-left: 4px;
                            }
                        }

                        #fuelList {
                            .p-checkbox-label {
                                margin-left: 8px;
                                padding-left: 4px;
                            }
                        }

                        .row {
                            div[class*='col-'] {
                                padding: 0;
                                display: flex;
                                align-items: center;
                                margin-bottom: 6px;

                                .p-checkbox-label {
                                    white-space: pre-wrap;
                                    margin-bottom: 0;
                                    margin-left: 8px;
                                    padding-left: 0;
                                }

                                .station-type-image {
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 480px) {
            width: 100%;
        }
    }
}

.order-type-icon {
    img.icon-svg {
        width: 100%;
        height: 100%;
        margin: 0px;
        cursor: pointer;
    }

    .selected {
        border: 2px solid #3059e8;
        border-radius: 8px;
    }
}

.p-menu {
    .p-menu-list {
        .p-menuitem {
            .p-menuitem-link {
                list-style: none;
                list-style-type: none;
                justify-content: center;
            }
        }
    }
}

.order-cards-table {
    margin-bottom: 12px;
}

.min-height-130 {
    min-height: 130px;
    position: relative;

    @media (max-width: 1187px) {
        min-height: 189.4px;
    }
}

.font-14-500 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.font-14-400 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #090e22;
}

.font-14-400.right {
    text-align: right;
}

.font-14-400.gray {
    color: #737786;
}

.font-14-600-right {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    color: #090e22;
}

.font-16-600 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.font-12-600 {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
}

.font-12-500 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
}

.font-12-700 {
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}

.font-12-600 {
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
}

.font-18-600 {
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
}

.gray {
    color: #737786;
}

.font-14-700 {
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
}

.line-height {
    line-height: 24px;
}

.text-iron {
    color: #444757;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.p-menu.p-component {
    border: 1px solid #e4e6f0;
    border-radius: 8px;

    .p-menuitem {
        border: 1px solid #e4e6f0;
        border-radius: 8px;
        margin: 16px;

        .p-menuitem-text {
            font-weight: 600;
            line-height: 14x;
            font-size: 12px;
            letter-spacing: 1%;
            color: #090e22;
        }
    }
}

.max-88-icon {
    max-height: 88px;
    max-width: 88px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-top-8 {
    margin-top: 8px;
}


.font-16-600.left {
    text-align: left;
}

.margin-top-5 {
    margin-top: 5px;
}

.inline-dropdown {
    padding-top: 12px;
    padding-bottom: 12px;
}

.big-checkbox {
    .p-checkbox-label {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
    }

    margin-bottom: 16px;
}

.font-blue {
    color: #3059e8;
}

.margin-left-8 {
    margin-left: 8px;
}

.inline-element {
    display: inline;
}

.black-labels {
    color: white;
    background-color: #090e22;
    padding: 4px;
    // margin-top: 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 4px;
    border: 1px solid transparent;
}

.white-labels {
    background-color: white;
    color: #090e22;
    padding: 4px;
    //  margin-top: 12px;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #cfd2dc;
}

.no-underline {
    text-decoration: none;
}

@media (max-width: 359px) {
    main .p-banner {
        padding: 10px;

        .banner-container {
            .right-banner-icons {
                .right-banner-icon:not(:last-child) {
                    margin-right: 6px;
                }
            }
        }
    }
}

.flex-container {
    display: flex;
}

.flex-end {
    justify-content: flex-end;
}

.flex-inputs {
    width: unset;
    display: flex;
}

.blue-banner {
    background-color: #eef8ff;

    .p-banner-text {
        color: #3059e8;
        background: transparent;
    }
}

.red-banner {
    background-color: #fff0f4;

    .p-banner-text {
        color: #dc043e;
        background: transparent;
    }
}

.tooltip-component {
    display: none;
    border-radius: 8px;
    padding: 10px;
    background-color: white;
    box-shadow: 4px 4px 4px rgba(23, 28, 115, 0.1);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    position: absolute;
    bottom: 100%;
    z-index: 1000;
}

.tooltip-label.start-page {
    .tooltip-component {
        bottom: unset;
        top: 22px;
        min-width: 200px;
    }

    .icon-svg.right-label {
        margin-left: 4px;
        margin-bottom: 0;
        color: #3059e8;
    }

    .p-label {
        align-items: center;
    }
}

.tooltip-label {
    position: relative;

    .row {
        display: inline-flex;
    }
}

.tooltip-component-active {
    display: block;
}

.p-dialog-mask {
    z-index: 1400;

    .p-dialog {
        max-width: 900px;
        border-radius: 12px;

        .dashboard .dashboard-link-container.limits .row-32 {
            padding-left: 12px;
            padding-right: 12px;
        }

        .dashboard .dashboard-link-container .fleet-good-group .product-label.card-details {
            height: auto;
        }

        .dashboard .dashboard-link-container .fleet-good-group .product-label:not(:last-child) {
            margin-right: 4px;
        }

        .dashboard .dashboard-link-container .fleet-good-group .product-label {
            padding: 4px;
            border-radius: 3px;
            border: 1px solid #cfd2dc;
            width: auto;
            font-size: 12px;
            line-height: 14px;
            height: 24px;
            font-weight: 600;
        }

        .p-dialog-header {
            padding-bottom: 20px;
            padding-left: 23px;
            padding-right: 23px;
            // border-top-left-radius: 12px;
            // border-top-right-radius: 12px;

            .p-dialog-title {
                font-size: 20px;
                font-weight: 700;
                line-height: 32px;
                text-align: left;
                color: #090e22;
            }

            .p-dialog-header-icons .p-dialog-header-close.p-link {
                background-color: #f4f5fc;
                border: none;
                margin-left: 8px;

                .p-dialog-header-close-icon.pi.pi-times {
                    color: $timehero-light-color1;
                }
            }

            .label-card-manage-description {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-top: 20px;
            }
        }

        .p-dialog-content {
            padding-left: 23px;
            padding-right: 23px;
            padding-bottom: 23px;
            // border-bottom-left-radius: 12px;
            // border-bottom-right-radius: 12px;

            .p-card {
                box-shadow: none;

                .p-card-body {
                    padding: 0px;

                    .p-card-content {
                        padding: 0px 0px;

                        .row {
                            .card-manage-limits {
                                margin-top: 20px;
                                margin-bottom: 14px;
                            }

                            .card-manage-tab-view {
                                .p-tabview-nav-container {
                                    padding-bottom: 16px;

                                    .p-tabview-nav-link:first-child {
                                        padding-left: 0px;
                                    }
                                }

                                .p-tabview-panels {
                                    padding-left: 0px;
                                }
                            }

                            .form-group {
                                --bs-gutter-y: 4px;

                                .p-checkbox {
                                    height: 24px;
                                    min-width: 24px;

                                    .p-checkbox-box {
                                        height: inherit;
                                        min-width: inherit;
                                    }
                                }

                                .p-checkbox-label {
                                    padding-top: 4px;
                                    padding-bottom: 4px;
                                    border-radius: 3px;
                                    // margin-left: 8px;
                                }

                                .p-checkbox-label.p-label.gray-description {
                                    padding: 0 0 0 12px;
                                    margin-left: 20px;
                                }

                                .p-checkbox-label.p-label {
                                    padding: 0 0 0 12px;
                                    margin-left: 0;
                                }
                            }
                        }

                        .row.margin-bottom-40 {
                            --bs-gutter-y: 38px;
                        }

                    }

                    .p-card-footer {
                        .footer-dialog-button-container {
                            display: flex;
                            justify-content: flex-end;

                            .p-button {
                                min-width: min-content;
                                width: fit-content;
                                margin-right: 16px;
                                height: 48px;
                            }

                            .p-button:last-child {
                                margin-right: 0px;
                            }

                            .left-footer-dialog-button-container {
                                flex: 1 1 auto;
                                margin-right: 16px;

                                .p-button {
                                    min-width: min-content;
                                    width: fit-content;
                                    margin-right: 16px;
                                }

                                .p-button:last-child {
                                    margin-right: 0px;
                                }
                            }
                        }

                        @media (max-width: 400px) {
                            .footer-dialog-button-container {
                                flex-wrap: wrap;

                                .p-button {
                                    text-align: center;
                                    width: 100%;
                                    margin-right: 0px;
                                    margin-bottom: 10px;

                                    span {
                                        width: 100%;
                                    }
                                }

                                .p-button:last-child {
                                    margin-bottom: 0px;
                                }

                                .left-footer-dialog-button-container {
                                    width: 100%;
                                    margin-right: 0px;
                                    margin-bottom: 10px;

                                    .p-button {
                                        text-align: center;
                                        width: 100%;
                                        margin-right: 0px;
                                        margin-bottom: 10px;
                                    }

                                    .p-button:last-child {
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                        }

                        .card-manage-footer {
                            padding-top: 48px;
                        }

                        .required-dialog-footer {
                            padding-top: 24px;
                        }
                    }
                }
            }

            .col-width-auto {
                width: auto;

                .row {
                    .form-group.checkbox {
                        display: flex;
                    }
                }

                @media (max-width: 575px) {
                    width: 50%;
                    flex: 0 1 auto;
                }
            }

            .add-button-row {
                display: flex;

                @media (max-width: 767px) {
                    justify-content: center;
                }

                .p-button {
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .trash-input {
                display: flex;
                align-items: flex-start;
                padding: 0;
                padding-top: 18px;
            }
        }
    }

    .p-dialog.transaction-dialog,
    .p-dialog.transaction-dialog-edit {
        min-width: 60vw;

        .p-dialog-header {
            padding: 36px 20px;

            .p-dialog-title {
                font-size: 16px;
            }
        }

        .p-dialog-content {
            padding: 0 20px 36px 20px;

            .row.transaction-buttons {
                margin-top: 16px;
                --bs-gutter-x: 16px;
                --bs-gutter-y: 16px;

                .transaction-button {
                    display: flex;

                    .p-button {
                        width: 100%;
                    }
                }
            }

            .row {
                .p-label {
                    font-weight: 600;
                    color: #090e22;
                }

                .p-inputtext-view {
                    font-weight: 400;
                }

                .col-width-auto.button {
                    flex: 1 1 auto;

                    @media (max-width: 767px) {
                        margin-top: 16px;
                        width: 50%;
                        flex: 0 0 auto;
                        margin-right: 0px;
                        margin-left: auto;
                    }

                    @media (max-width: 467px) {
                        width: 100%;
                    }
                }

                .p-button {
                    height: 48px;
                    width: auto;
                    display: flex;
                    justify-content: center;
                    justify-items: center;
                    padding: 0;
                    white-space: nowrap;
                }

                .p-datatable-responsive {
                    tr {
                        border: none;

                        > th {
                            padding: 4px 4px;
                            border: none;
                        }

                        > th:first-child {
                            padding-left: 0;
                        }

                        > th:last-child {
                            padding-right: 0;
                        }

                        > td {
                            padding: 4px 4px;
                        }

                        > td:first-child {
                            padding-left: 0;
                        }

                        > td:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }

            .transaction-amount-container {
                display: inline-flex;
                flex-direction: column;
                width: auto;

                .transaction-amount {
                    width: 100%;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    color: #090e22;
                    white-space: pre;
                }

                .transaction-amount-vat,
                .transaction-amount-netto {
                    color: #8e92a3;
                    width: 100%;
                    font-style: normal;
                    font-size: 11px;
                    line-height: 17px;
                    font-weight: 400;
                }
            }
        }

        .input-text-number-value {
            span {
                justify-content: flex-end;
            }
        }
    }

    .p-dialog.transaction-dialog-edit {
        max-width: 430px;
    }

    .p-dialog.transaction-video-dialog {
        max-width: 90vw;
        width: 100%;
        max-height: 90vh;

        .video-player-wrapper {
            position: relative;
            padding-top: 56.25%;
            /* 720 / 1280 = 0.5625 */

            .react-player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .transaction-video {
                width: 100%;
                height: auto;
            }
        }
    }

    .p-dialog.contact-dialog {
        width: 100%;
        max-width: 624px;

        .file-row {
            display: flex;
            justify-content: space-between;

            .col-width-auto {
                width: unset;
                max-width: calc(100% - 184px);
            }

            .button-col {
                display: flex;
                justify-content: flex-end;
                width: fit-content;

                .p-button {
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 160px;
                }
            }

            @media (max-width: 467px) {
                .col-width-auto {
                    width: 100%;
                    max-width: 100%;
                }

                .button-col {
                    width: 100%;
                    justify-content: center;

                    .p-button {
                        width: 100%;
                    }
                }
            }
        }

        .attachment-label {
            display: flex;
            width: auto;
            align-items: center;
            justify-content: center;

            .attachment-label-icon {
                height: 20px;
                width: 20px;
            }

            .attachment-label-text {
                margin-left: 6px;
                line-height: 22px;
            }
        }

        .file-choose-file {
            display: flex;
            width: fit-content;
            max-width: 100%;
            line-height: 22px;
            padding: 12px;
            border: 1px solid #e4e6f0;
            border-radius: 8px;

            @media (max-width: 575px) {
                margin-bottom: 6px;
            }

            .file-choose-file-name {
                max-width: 90%;
                display: block;
                overflow-wrap: break-word;
            }

            .file-upload-actionlink {
                margin-left: 6px;
                height: 22px;
                max-width: 10%;

                .icon::before {
                    font-size: 24px;
                    vertical-align: middle;
                }
            }
        }

        .files-input-proxy {
            width: fit-content;
            padding: 12px;
            font-size: 12px;
            color: #090e22;
            background: white;
            border: 1px solid #e4e6f0;
            border-radius: 8px;

            @media (max-width: 575px) {
                margin-bottom: 6px;
            }

            .p-button-icon::before {
                content: '\e97b';
                font-size: 20px;
                color: #090e22;
            }
        }
    }

    .p-dialog.profile-dialog {
        width: 1032px;

        @media (max-width: 1400px) {
            min-width: 90%;
        }
    }

    .p-dialog.card-limit-dialog {
        min-width: 600px;

        @media (max-width: 1400px) {
            min-width: 90%;
        }
    }

    .p-dialog.login-helpdesk-dialog {
        .p-dialog-header {
            padding: 14px 14px 20px 28px;

            .p-dialog-title {
                font-size: 22px;
                line-height: 32px;
            }

            .p-dialog-header-close-icon::before {
                font-weight: 900;
            }
        }

        .p-dialog-content {
            .form-group {
                margin-bottom: 24px;

                .p-label {
                    margin-bottom: 6px;
                }
            }

            .button-row {
                display: flex;
                justify-content: center;

                .p-button {
                    width: 130px;
                    height: 48px;
                }
            }
        }

        min-width: 400px;
        width: 400px;

        @media (max-width: 400px) {
            min-width: unset;
            width: 100%;
        }
    }

    .start-top-up-dialog {
        width: 600px;

        @media (max-width: 600px) {
            width: 100%;
        }

        .p-dialog-content {
            div.col-12 {
                > span {
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    color: #dc043e;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 40px;
                }

                .top-up-confirmed {
                    margin-bottom: 20px;
                }
            }

            .p-inputtext {
                margin-bottom: 16px;
            }

            .p-button {
                width: auto;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 32px;
            }
        }
    }

    .p-dialog.order-fuel-dialog {
        width: 480px;

        @media (max-width: 480px) {
            width: 100%;
        }
    }

    .p-dialog.reports-dialog {
        position: relative;
        width: 480px;

        @media (max-width: 480px) {
            width: 100%;
        }

        .p-dialog-header {
            label {
                font-size: 16px;
                line-height: 20px;
                white-space: pre;
            }
        }

        .date-selection {
            margin-bottom: 30px;

            .p-label {
                margin-bottom: 0;
            }

            .range-dash {
                display: block;
                padding: 0px;
                height: 3px;
                background: #090e22;
                width: 6px;
                margin-top: 32px;
                margin-right: -4px;
                margin-left: -4px;
            }
        }

        .columns-checkboxes {
            .row {
                .col-12 {
                    .p-checkbox {
                        margin-bottom: 30px;
                    }
                }

                .col-md-12 {
                    .p-checkbox {
                        margin-bottom: 30px;
                    }
                }

                .col-12:last-child {
                    .p-checkbox {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .button-col {
            margin-top: 25px;
            display: flex;
            justify-content: space-between;

            .p-button {
                width: auto;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .p-datatable {
            margin: 4px 0 30px 0;

            .p-datatable-thead {
                tr {
                    display: none;
                }
            }

            th {
                display: none;
            }

            tr {
                z-index: 2;
                // border: 1px solid #E4E6F0;
                border: none;
                border-left: none;
                border-right: none;

                td {
                    width: auto;

                    .p-datatable-reorderablerow-handle {
                        background-image: url('../img/ico_reports_drag.svg');
                        width: 24px;
                        height: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .p-datatable-reorderablerow-handle::before {
                        display: none;
                    }

                    line-height: 22px;
                    vertical-align: bottom;
                }

                td:first-child {
                    border-radius: 0;
                    width: 40px;
                    padding-right: 0;
                }

                td:last-child {
                    border-radius: 0;
                }
            }

            tr::before {
                content: '';
                z-index: 1;
                position: absolute;
                border-top: 1px solid #e4e6f0;
                width: 100%;
                border-left: none;
                border-right: none;
                left: 0;
            }

            tr:last-child::before {
                z-index: 1;
                height: 56px;
                border-bottom: 1px solid #e4e6f0;
                pointer-events: none;
            }
        }
    }

    .p-dialog.change-password-dialog {
        width: 330px;

        @media (max-width: 330px) {
            width: 100%;
        }
    }

    .p-dialog.order-fuel-confirmation-dialog {
        width: 590px;

        @media (max-width: 530px) {
            width: 100%;
        }

        .p-dialog-header {
            padding: 12px;

            .p-dialog-header-icons {
                display: none;
            }
        }

        .p-dialog-content {
            .header {
                padding-bottom: 16px;
                color: #090e22;
            }

            .icon-svg {
                margin-right: 18px;
                width: 20px;
                height: 20px;
            }

            .order-number-row {
                padding-top: 4px;
                display: flex;
                padding-left: 38px;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;

                .bold {
                    font-weight: 700;
                    padding-left: 4px;
                }
            }

            .redirect {
                padding-left: 38px;
                padding-top: 32px;
                font-weight: 400;
                font-size: 12px;
                color: #737786;
                text-align: left;
            }

            .icon-text {
                display: flex;

                .order-fuel-financial-auth {
                    .bold {
                        font-weight: 600;
                    }

                    color: #737786;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                }
            }

            .icon-text.financial-auth {
                white-space: pre-line;
            }

            .tips {
                padding-top: 16px;
                font-size: 14px;
                line-height: 21px;
                color: #737786;
            }

            .attachment {
                width: 260px;

                @media (max-width: 360px) {
                    width: 100%;
                }

                .attachment-label-empty {
                    width: 100%;
                    border: dashed 1px $timehero-light-color1;
                    border-radius: 16px;
                    background: #eef8ff;
                    height: 128px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .attachment-label-icon {
                        width: 50px;
                        height: 50px;
                    }

                    .attachment-label-text {
                        margin-top: 18px;
                        color: $timehero-light-color1;
                        font-weight: 500;
                        line-height: 16px;
                        font-size: 12px;
                    }
                }

                .form-group {
                    > div {
                        width: 100%;
                    }

                    .attachment-label-icon {
                        margin-right: 10px;
                    }

                    .file-choose-file {
                        display: flex;

                        .file-choose-file-name {
                            margin-right: 6px;
                        }
                    }
                }

                .attachment-label {
                }
            }
        }
    }

    .p-dialog.invoice-download-dialog {
        width: 530px;

        @media (max-width: 530px) {
            width: 100%;
        }

        .invoice-property {
            display: flex;
            white-space: pre;

            .invoice-property-value {
                font-weight: 600;
            }
        }

        .product-list {
            color: #090e22;
            padding: 20px 30px;

            .product-list-header {
                font-weight: 600;
                display: flex;
                justify-content: space-between;
            }

            .product-list-row {
                font-size: 14px;
                display: flex;
                justify-content: space-between;
            }
        }

        .button-row {
            display: flex;
            justify-content: center;

            .p-button {
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 320px;
            }
        }
    }

    .p-dialog.card-order-template-dialog {
        .p-card {
            .p-card-header {
                color: #090e22;
            }
        }
    }
}

.p-multiselect-panel {
    .p-multiselect-header {
        .p-checkbox-box.p-highlight {
            background-color: $timehero-light-color1;
            border: none;
        }

        // todo focus
    }

    .p-multiselect-items-wrapper {
        .p-multiselect-items {
            .p-multiselect-item {
                padding-bottom: 28 px;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #090e22;
            }

            .p-multiselect-item.p-highlight {
                background-color: $timehero-light-color1;
                color: white;

                .p-checkbox-box {
                    background-color: $timehero-light-color1;
                    border: none;
                }
            }
        }
    }
}

.p-component {
    .p-checkbox-label.p-label {
        color: #090e22;
        font-size: 14px;
        max-width: calc(100% - 24px);
    }
}

.p-checkbox {
    vertical-align: top;

    .p-checkbox-box.p-highlight {
        background: $timehero-light-color1;
        border-color: $timehero-light-color1;
        border: none;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        border-color: $timehero-light-color1;
        background: $timehero-light-color1;
        box-shadow: none;
        color: #ffffff;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:focus {
        border-color: $timehero-light-color1;
        background: $timehero-light-color1;
        box-shadow: none;
        color: #ffffff;
    }

    .p-disabled.p-highlight {
        opacity: 1;
        background: $timehero-light-color1;
        background: #cfd2dc;

        .p-checkbox-icon.p-c.pi.pi-check {
            color: white;
        }
    }
}

// todo focus
.p-checkbox-label {
    //styleName: Header XXS;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #090e22;
    padding-left: 8px;
}

.pc .p-checkbox-icon.pi-check:before {
    content: '\e909';
    width: 14px;
    height: 14px;
    top: 8px;
    flex: none;
}

.p-dropdown-panel {
    .p-dropdown-items-wrapper {
        .p-dropdown-items {
            .p-dropdown-item {
                padding-bottom: 28 px;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #090e22;
            }

            .p-dropdown-item.p-highlight {
                background-color: $timehero-light-color1;
                color: white;
            }
        }
    }
}

.p-autocomplete:not(.p-disabled):hover {
    .p-autocomplete-input,
    .p-autocomplete-dropdown {
        border-color: $timehero-color4;
    }
}

.p-autocomplete:not(.p-disabled):focus-within {
    border-radius: 8px;
    box-shadow: 0 0 0 0.2rem #a6d5fa;

    .p-autocomplete-input,
    .p-autocomplete-dropdown {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        border-color: $timehero-color4;
    }
}

.p-autocomplete-panel {
    .p-autocomplete-items {
        .p-autocomplete-item {
            font-size: 12px;
        }
    }
}

.p-autocomplete {
    display: flex;
    background-color: transparent;
    border: none;
    line-height: 20px;
    height: auto;
    padding: 0;

    .p-autocomplete-input {
        border-right: none;
    }

    .p-autocomplete-multiple-container {
        // padding: 6px 11px 14px 13px;
        padding: 8px 11px 8px 13px;
        width: 100%;

        .p-autocomplete-input-token {
            input {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .p-autocomplete-dropdown {
        background-color: #f4f5fc;
        color: $dark-dark-green;
        border: 1px solid #e4e6f0;
        border-radius: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;

        .p-button-icon {
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 20px;
        }
    }

    .p-autocomplete-dropdown:hover {
        //background-color: #f4f5fc;
        //color: $dark-dark-green;
    }

    .p-dropdown-items-wrapper {
        .p-dropdown-items {
            .p-dropdown-item {
                padding-bottom: 28 px;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: $dark-dark-green;
            }

            .p-dropdown-item.p-highlight {
                background-color: $timehero-light-color1;
                color: white;
            }
        }
    }

    .p-dropdown-label {
        padding: 0;
        color: #444757;
        font-weight: 500;
        line-height: 20px;
    }

    .dropdown-label {
        white-space: break-spaces;
    }
}

.title-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #090e22;
    padding-bottom: 24px;
}

.colorfull-label {
    color: white;
    padding: 4px;
    margin-top: 12px;
    //styleName: Body S Bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    border-radius: 3px;
    border: 1px solid transparent;
}

#main-login {
    background-image: url($timehero-login-backgroung-image);
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    width: 100%;
    float: none;
    display: inline-block;
    vertical-align: middle;
    margin-right: -4px;
    min-height: 100vh;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    
    .login-pane > .row {
        height: 100%;
    }

    .login-pane > .form-div {
        padding-right: 30px;
        padding-left: 0px;
        position: relative;
        height: 100%;
    }
}



.srv-validation-message {
    color: tomato;
}

body nav .container {
    padding-right: 0px;
    padding-left: 0px;
}

.cookie-text {
    text-align: justify;
    padding-bottom: 90px;
}

.p-datepicker.p-component {
    padding: 0px;
    border-radius: 12px;

    .p-datepicker-group-container .p-datepicker-group .p-datepicker-header {
        border-bottom: none;
        border-radius: 12px;

        .p-datepicker-title {
            padding-top: 24px;

            .p-datepicker-month {
                padding: 8px;
                border: none;
                background: #f8f7fa;
                color: #3059e8;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                border-radius: 8px;

                option {
                    color: black;
                }
            }

            .p-datepicker-year {
                padding: 8px;
                border: none;
                background: transparent;
                color: #19181a;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                border-radius: 8px;
            }
        }
    }
}

.p-datepicker-buttonbar {
    display: flex;
}

.p-datepicker-calendar-container {
    margin-left: 40px;
    margin-right: 40px;
}

.p-datepicker-calendar {
    max-height: 360px;
    padding-left: 40px;
    padding-right: 40px;

    thead span {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
    }

    thead th {
        text-align: center;
    }

    tbody {
        td {
            padding: 0px;
        }

        span {
            border-radius: 40px;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
        }

        td span.p-highlight {
            background: #3059e8;
            color: white;
        }

        td.p-datepicker-today {
            span {
                color: #3059e8;
                background: #f8f7fa;
            }
        }
    }
}

button.p-datepicker-next.p-link {
    display: none;
}

button.p-datepicker-prev.p-link {
    display: none;
}

.min-width-content {
    width: fit-content;
}

.calendar-icon-white-bg {
    .p-calendar {
        .p-datepicker-trigger {
            background-color: white;
        }

        .p-datepicker-trigger:hover {
            background-color: $timehero-light-color1;
        }
    }
}

.center-all {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.left-label {
    padding-right: 2px;
}

.red-star {
    color: #dc043e;
    padding-bottom: 4px;
}

.p-progressbar .p-progressbar-value {
    background: #3059e8;
}

.p-tabview .p-tabview-nav {
    li.p-highlight {
        .p-tabview-nav-link {
            border: #3059e8 solid;
            border-width: 0 0 2px 0;
            color: #3059e8;
        }

        .p-tabview-nav-link:not(.p-disabled):focus {
            box-shadow: none;
        }
    }
}

.col-width-flex {
    flex: 1;
}

.col-width-auto {
    width: auto;
}


body .button-click-area {
    position: relative;

    .balance-details-icon {
        height: 8px;
        width: 5px;
        margin: 0;
        margin-left: 3px;
    }
}

body .button-click-area::before {
    content: '';
    position: absolute;
    display: block;
    top: 2px;
    left: -5px;
    height: 20px;
    width: 20px;
    border-radius: 12px;
    cursor: pointer;
}

.change-blocked-container {
    justify-content: unset;
    margin-top: 16px;

    .change-blocked-label {
        white-space: break-spaces;
    }
}

@media (max-width: 1000px) {
    .p-dialog-mask .p-dialog.card-order-template-dialog,
    .p-dialog-mask .p-dialog.card-list-manage-dialog {
        min-width: 95vw;
        max-width: 95vw;
    }
}

@media (max-width: 450px) {
    .p-dialog-mask .p-dialog.card-order-template-dialog,
    .p-dialog-mask .p-dialog.card-list-manage-dialog {
        min-width: 100vw;
        max-width: 100vw;
    }
}

.p-dialog-mask .p-dialog.contract-terms-dialog {
    min-width: 95vw;
    max-width: 95vw;
    min-height: 95vh;
    max-height: 95vh;

    .p-dialog-content {
        overflow-y: hidden;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        align-content: space-around;
        justify-content: space-between;
        align-items: stretch;
        flex-grow: 1;

        .p-card {
            overflow-y: hidden;
            height: 100%;
            max-height: 100%;
            display: flex;
            flex-direction: column;
            align-content: space-around;
            justify-content: space-between;
            align-items: stretch;
            flex-grow: 1;

            .p-card-body {
                overflow-y: hidden;
                height: 100%;
                max-height: 100%;
                display: flex;
                flex-direction: column;
                align-content: space-around;
                justify-content: space-between;
                align-items: stretch;
                flex-grow: 1;

                .p-card-content {
                    overflow-y: hidden;
                    height: 100%;
                    max-height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-content: space-around;
                    justify-content: space-between;
                    align-items: stretch;
                    flex-grow: 1;

                    form {
                        overflow-y: scroll;
                        height: 100%;
                        max-height: 100%;

                        .contract-terms-container {
                            overflow-y: hidden;

                            // height: 100%;
                            // max-height: 100%;
                            // display: flex;
                            // align-content: space-around;
                            // justify-content: center;
                            // align-items: stretch;
                            // flex-grow: 1;
                            html {
                                overflow-y: hidden;
                            }
                        }
                    }
                }
            }
        }
    }
}


.p-dialog-mask {
    .p-dialog.confirm-dialog-width {
        min-width: 547px;
        max-width: 547px;

        .p-dialog-header {
            padding-bottom: 32px;

            .confirm-dialog-icon {
                padding-bottom: 36px;
            }
        }

        .p-dialog-content {
            .invoice-property {
                white-space: pre-wrap;

                .bold {
                    font-weight: 600;
                }

                .card-icon {
                    padding-left: 4px;
                }
            }

            .invoice-property.desc {
                padding-bottom: 24px;
            }

            .p-card .p-card-body {
                .order-confirmation-dialog-body-label {
                    white-space: pre-wrap;
                }

                .p-card-footer {
                    padding-top: 48px;

                    .footer-dialog-button-container {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }

    @media (max-width: 600px) {
        .p-dialog.confirm-dialog-width {
            min-width: 95vw;
            max-width: 95vw;
        }
    }

    @media (max-width: 450px) {
        .p-dialog.confirm-dialog-width {
            min-width: 100vw;
            max-width: 100vw;
        }
    }
}

@media (min-width: 1920px) {
    body div#root .container-fluid {
        max-width: 1860px;
    }
    .container-fluid .footer {
        position: absolute;
        left: 150px;
        max-width: 100000px;
    }
    body div#root .app main .auto-register-container .container-fluid {
        max-width: 1320px;

        .final-page {
            max-width: 900px;
        }
    }
}

@media (max-width: 1920px) {
    body div#root .container-fluid {
        max-width: 1720px;
    }

    .container-fluid .footer {
        position: absolute;
        left: 150px;
        max-width: 100000px;
    }

    body div#root .app main .auto-register-container .container-fluid {
        max-width: 1032px;

        .final-page {
            max-width: 720px;
        }
    }
}

@media (max-width: 1600px) {
    body div#root .app main .auto-register-container .container-fluid {
        max-width: 960px;

        .final-page {
            max-width: 640px;
        }
    }
}

@media (max-width: 1100px) {
    .cookie-text {
        padding-bottom: 115px;
    }
}

@media (max-width: 1023px) {
    body {
        .p-dialog-mask {
            padding-left: 0;
        }

        .app.logged {
            padding-top: 64px;
        }

        .app {
            main > div.container-fluid {
                padding-left: 24px;
                padding-right: 24px;
            }

            main.aaa {
                margin-top: 0;
            }

            .sidebar-vertical {
                display: none;
            }

            .sidebar-horizontal {
                display: flex;
                position: absolute;
                width: 100%;
                z-index: 300;
                top: 0;

                .sidebar {
                    display: block;
                    width: 0px;
                    z-index: 305;

                    .pro-sidebar-background {
                        transition: width 0.5s;
                        position: fixed;
                        border-radius: 0;
                        width: 0px;
                        box-shadow: 0px 4px 17px rgba(48, 89, 232, 0.57);
                        clip-path: inset(0px -15px 0px 0px);
                        z-index: 304;
                    }

                    .pro-sidebar-layout {
                        position: fixed;
                        width: 0px;
                        transition: width 0.5s;

                        .pro-sidebar-content {
                            .pro-menu {
                                .pro-menu-item {
                                    .pro-inner-item {
                                        .pro-item-content {
                                            white-space: nowrap;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .sidebar.functions-menu {
                    display: block;
                    width: 0px;
                    z-index: 305;

                    .pro-sidebar-footer {
                        text-align: center;

                        .sidebar-version {
                            height: 12px;
                            font-size: 9px;
                            color: white;
                        }
                    }

                    .pro-sidebar-background {
                        transition: width 0.5s;
                        position: fixed;
                        border-radius: 0;
                        width: 0px;
                        box-shadow: 0px 4px 17px rgba(48, 89, 232, 0.57);
                        clip-path: inset(0px -15px 0px 0px);
                        z-index: 304;
                        right: 0;
                        height: 240px;
                    }

                    .pro-sidebar-layout {
                        position: fixed;
                        width: 0px;
                        transition: width 0.5s;
                        right: 0;
                        background: #1e2383;

                        .pro-sidebar-content {
                            .pro-menu {
                                padding-bottom: 10px;

                                .pro-menu-item {
                                    .pro-inner-item {
                                        .pro-item-content {
                                            white-space: nowrap;

                                            span {
                                                white-space: nowrap;
                                            }
                                        }
                                    }
                                }

                                .sidebar-submenu {
                                    .pro-menu {
                                        padding-bottom: 0;

                                        .pro-menu-item:first-child {
                                            padding-top: 5px;
                                        }

                                        .pro-inner-item {
                                            padding: 5px 5px 5px 20px;
                                        }
                                    }

                                    .pro-menu:first-child {
                                        padding-top: 10px;
                                    }

                                    .sidebar-submenu-items {
                                        position: relative;
                                        transition: height 0.5s;
                                        height: 0;
                                        padding: 0;
                                    }

                                    .sidebar-submenu-items.show {
                                        height: 50px;
                                    }
                                }
                            }
                        }
                    }
                }

                .sidebar.show-menu {
                    position: relative;
                    width: 220px;
                    height: 0px;

                    .show-menu-overlay {
                        position: fixed;
                        height: 100vh;
                        width: 100vw;
                        display: block;
                        z-index: 301;
                        background-color: rgba(0, 0, 0, 0.4);
                    }

                    .pro-sidebar-background.show-menu {
                        width: 220px;
                        z-index: 302;
                    }

                    .pro-sidebar-layout {
                        width: 220px;
                        z-index: 303;
                    }
                }

                .pro-sidebar-layout {
                    height: auto;
                }

                .pro-sidebar {
                    width: 220px;
                    min-width: 220px;

                    .pro-inner-item.active {
                        margin-right: 20px;
                    }
                }
            }

            main {
                .stations-map-container {
                    .row.stations-map {
                        width: calc(100% + 48px);
                        margin: 0 0 0 -24px;

                        > .row {
                            padding: 0;
                        }
                    }

                    .google-map-wrapper {
                        height: calc(100vh - 164px);
                    }

                    .row {
                        .stations-map-1024up {
                            display: none;
                        }

                        .col-md-8 {
                            width: 100%;
                            padding: 0;
                        }

                        .row {
                            .map-location-embedded {
                                display: flex;
                                padding: 0;
                                box-shadow: 0px 4px 4px rgba(23, 28, 115, 0.1);

                                div[class*='col'] {
                                    padding: 0;
                                }

                                .map-location-embedded-buttons {
                                    display: flex;
                                    position: absolute;
                                    right: 20px;

                                    .white {
                                        border: none;
                                        background: transparent;
                                    }

                                    .p-button {
                                        width: auto;
                                        padding: 0;
                                    }

                                    .p-button:first-child {
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .p-datatable-responsive {
        .p-datatable-wrapper {
            overflow: auto;
        }

        .p-datatable-thead {
            .p-column-title {
                white-space: nowrap;
            }
        }
    }

    .auto-register-container > .row {
        margin: auto;
    }

    body div#root .app main .auto-register-container .container-fluid {
        max-width: 900px;

        .auto-register {
            .p-card {
                .p-card-content {
                    padding: 8px 5px 16px;
                }
            }
        }

        .final-page {
            max-width: 640px;
        }
    }
}

@media (max-width: 860px) {
    .cookie-text {
        padding-bottom: 175px;
    }
}


@media (max-width: 575px) {
    body div#root .container-fluid {
        div.p-card-body {
            .row {
                .balance-buttons {
                    .row {
                        margin-top: 24px;
                        padding: 0 12px 0 12px;

                        .transactions-button {
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }
    }

    body div#root .navbar-baner {
        height: 0px;
    }

    body div#root .navbar-light .navbar-toggler {
        margin-left: 10px;
    }

    body div#root .dashboard .dashboard-link {
        display: none;
    }

    body div#root .dashboard .dashboard-separator-container {
        width: 100%;
        padding-left: 0px;
    }
}

@media (max-width: 550px) {
    .cookie-text {
        padding-bottom: 245px;
    }
}

@media (max-width: 467px) {
    body {
        .p-steps {
            display: flex;
            justify-content: flex-start;
            padding-left: 16px;

            ul {
                flex-direction: column;
                align-items: flex-start;

                .p-steps-item::before {
                    border-top: none;
                    border-left: 2px solid $timehero-light-color1;
                    height: 100%;
                    left: 11px;
                }

                .p-steps-item:first-child::before {
                    left: 11px;
                    top: 100%;
                    height: 50%;
                }

                .p-steps-item:last-child::before {
                    left: 11px;
                    top: 50%;
                    height: 50%;
                }

                .p-steps-item {
                    flex: 0;
                    width: auto;
                    justify-content: flex-start;

                    .p-menuitem-link {
                        margin-bottom: 6px;
                        flex-direction: row;
                    }

                    .p-steps-title {
                        margin: 0 0 0 6px;
                    }
                }

                .p-steps-title {
                }

                .p-steps-title:before {
                    white-space: nowrap;
                }
            }
        }
    }

    body div#root main > .container-fluid {
        height: 170px;
        padding: 0 16px 0 16px;

        .list-card {
            .p-card-body {
                padding: 0;

                .p-accordion-content {
                    padding: 24px 16px 24px 16px;
                }
            }
        }

        h2.pageheader-title {
            padding: 16px;
        }

        .header-content {
            .header-welcome {
                font-size: 16px;
                line-height: 20px;
            }
        }

        .profile-container .p-card-body {
            padding: 0;
        }

        .card-list-container {
            .p-card-content .p-card-header-minheight {
                .list-title-header {
                    flex: 0 1 auto;
                }

                .header-item-right.float-right {
                    display: flex;
                    justify-content: flex-end;
                    margin-left: auto;
                }

                .header-item-right {
                    position: relative;
                    padding: 25px 0 0 12px;
                    --bs-gutter-x: 1rem;
                    display: flex;
                }

                .header-item {
                    width: 100%;
                    justify-content: left;
                }
            }
        }
    }

    body div#root .session-div {
        margin: auto;
    }

    body div#root .logout-button {
        float: right;
    }
}

@media (max-width: 340px) {
    body div#root main > .container-fluid {
        padding: 0 8px 0 8px;
        // .p-card-content{
        // .row {
        //     --bs-gutter-x: 1rem;
        // }}
    }

    body {
        .app {
            main {
                overflow-x: hidden;
            }
        }
    }
}

@media (min-width: 768px) {
    .col-md-20 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }
}

@media (min-width: 992px) {
    .col-lg-20 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }
}

.red-font-color {
    color: red;
    font-weight: 600;
}

#main-login {
    background-image: url($timehero-login-backgroung-image);
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    width: 100%;
    float: none;
    display: inline-block;
    vertical-align: middle;
    margin-right: -4px;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.login-pane {
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    width: 700px;
    //height: 360px;
    min-height: 260px;
    background-color: rgb(184 175 175 / 80%);    
    margin: auto;
}

.login-pane {
    .p-label {
        color: #ffffff;
        font-size: medium;
    }
}

.login-pane > .row {
    height: 100%;
}

.login-pane > .form-div {
    padding-right: 30px;
    padding-left: 0px;
    position: relative;
    height: 100%;
}

.login-pane {
    .login-pane-left {
        text-align: center;
    }
    .logo {
        width: auto;  
        height: $timehero-logo-image-height;
        background-image: url($timehero-logo-image);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
    }
    .login-links {
        padding-top: 5px;
        padding-right: 10px;
        padding-bottom: 20px;
        float: right;
        .p-link {
            color: white;
        }
    }
}

.welcome-text-header {
    color: white;
    font-size: 44px;
    font-family: 'Open Sans Condensed';
}

.welcome-text {
    color: white;
    font-size: 21px;
    font-family: 'Open Sans Condensed';
}

.login-label {
    font-size: 16px;
    font-family: 'Open Sans' !important;
    font-weight: 300;
    color: white;
    margin-bottom: 0px;
}

.login-text-input {
    font-family: 'Open Sans' !important;
    height: 40px !important;
}

body a.login-button {
    height: 40px !important;
    width: 100%;
    font-size: 16px !important;
    // position: absolute;
    bottom: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    background: $timehero-color1;
    border: none;
    color: white;
    text-align: center;
    display: table;
}

body div#root .p-button.dialog-button {
    height: 35px;
}

body a.login-button.p-button-text-only span.p-button-text {
    padding: 0px;
    font-family: 'Ubuntu' !important;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.55;
    display: table-cell;
    vertical-align: middle;
}

body a.login-button:hover {
    color: white;
    background: $timehero-color1;
}

body div#root a.lost-pass-label {
    font-size: 16px;
    font-family: 'Open Sans' !important;
    font-weight: 300;
    color: white;
    padding-top: 0px;
}

body div#root a.lost-pass-label:hover {
    color: white;
}

body a.lost-pass-label:hover {
    text-decoration: underline;
    cursor: pointer;
    color: yellowgreen;
}

#main-login .login-pane .form-div {
    padding-right: 30px;
    padding-left: 0px;
    position: relative;
    height: 100%;
}

#main-login .login-pane .form-div input {
    width: 100%;
    height: 40px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    padding-left: 8px;
}

.srv-validation-message {
    color: tomato;
}

body div#root .navbar {
    padding: 0px;
}

body div#root .navbar .navbar-light .navbar-toggler {
    margin-left: 10px;
}

body div#root .navbar-background {
    width: 100%;
}

body div#root #basic-navbar-nav {
    padding: 0px;
}



body div#root .header-logo {
    height: 36px;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 15px;
    padding-left: 15px;
}

body div#root .header-logo.moya-anwim {
    height: 40px;
    border-right: white solid 2px;
}

body div#root .header-logo.moya {
    border-right: white solid 2px;
}

body div#root .header-label {
    padding-top: 6px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 6px;
    flex: auto;
}

body div#root .header-label.user-label {
    font-size: 16px;
    color: white;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    content: none;
}

.save-btn-right {
    display: inline-flex;
    padding-inline-end: 10px;
}

.save-btn-left {
    display: inline-flex;
    padding-inline-end: 10px;
}
.footer-non-dialog-button-container {
    display: inline;

    .margin10 {
        margin-right: 5px;
    }
    .float-left-btn {
        float: left;
        display: flex;
    }
    .float-right-btn {
        float: right;
        display: flex;
        margin-left: 16px;
        a.p-button-text-only {
            margin-left: 2px;
        }
    }
}

.p-card-footer {
    .p-card-footer-left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        *:not(:first-child) {
            margin-left: 18px;
        }
        margin-bottom: 10px;
    }
    .p-card-footer-right-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        *:not(:first-child) {
            margin-left: 18px;
        }
        margin-bottom: 10px;
    }
}

.p-card-header-minheight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .header-item {
        width: unset;
    }

    .header-item-right {
        display: flex;
        right: 25px;
        width: unset;
        height: 100%;
        position: absolute;
        align-items: center;

        *:not(:first-child) {
            margin-left: 18px;
        }
    }
}

body .p-datatable .p-datatable-wrapper .p-datatable-tbody .number {
    text-align: right;
}

body .p-datatable .p-datatable-wrapper .p-datatable-tbody .center {
    text-align: center;
}

.p-datatable .p-datatable-tfoot .number {
    text-align: right;
}

body .p-datatable .p-datatable-wrapper .p-datatable-thead {
    .number {
        text-align: right;
    }
}

th.number > div {
    justify-content: flex-end;
}

.align-right {
    text-align: right;
}
.align-right-flex {
    justify-content: end;
    margin-right: 30px;
}

.reported-day-cars {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    padding-top: 10px;
    padding-left: 10px;

    .row {
        margin-top: 5px;
        margin-bottom: 10px;
    }
}
.reported-day-extra-ev {
    .bin-icon {
        margin-top: 130px;
    }
}
.footer {
    background: white;
    position: sticky;
    bottom: auto;
    z-index: 100;
}

.delete-button {
    background: $timehero-light-color1;
}

.addRowButton{
    background-color: white;
    border-color: #e4e6f0;
    .p-button-icon{
        color: #000000;
    }
}


.cpc-form-group {
    margin-top: 10px;
    .cpc {
        margin-bottom: 0px !important;
    }
}

.client-address-container {
    display: inline-flex;
    flex-direction: column;
    width: max-content;
    .client-address-header {
        width: 100%;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        color: $dark-dark-green;
        //  white-space: pre;
    }
    .client-adress {
        color: #8e92a3;
        width: 100%;
        font-style: normal;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
    }
}
.accommodation-address-container {
    display: inline-flex;
    flex-direction: column;
    width: max-content;
    max-inline-size: 250px;
    overflow-wrap: break-word;
    .accommodation-address-header {
        max-width: min-content; /* Adjusted to min-content */
        min-width: 100%;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        color: $dark-dark-green;
    }
    .accommodation-adress {
        color: #8e92a3;
        width: 100%;
        font-style: normal;
        font-size: 15px;
        line-height: 14px;
        font-weight: 400;
    }
}

.accommodation-dashboard-address-container {
    display: inline-flex;
    flex-direction: column;
    width: max-content;
    max-inline-size: 250px;
    overflow-wrap: break-word;
    .accommodation-address-header {
        max-width: min-content; /* Adjusted to min-content */
        min-width: 100%;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        color: $dark-dark-green;
    }
    .accommodation-adress {
        color: #000000;
        width: 100%;
        font-style: normal;
        font-size: 15px;
        line-height: 14px;
        font-weight: 400;
    }
}

.absence-container {
    display: inline-flex;
    flex-direction: column;
    width: max-content;
    .absence-header {
        width: 100%;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        color: $dark-dark-green;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

#tooltipIcon * {
    box-shadow: none;
}

.dashboard-teil {
    color: var(--bs-card-color);
    background-color: white;
    min-height: 200px;
    border: transparent;
    height: 200px;
    max-width: 500px;
    min-width: 100px;
    width: auto;
    //overflow-y: scroll;
    margin-bottom: 20px;
    .p-card-header {
        font-weight: bold;
    }
    .project-name {
        margin-top: 3px;
        width: 100%;
        border-color: transparent;
        background-color: transparent;
        height: 30px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 3px;
        .card {
            background-color: yellow;
        }
        .p-inputtext-view {
            font-size: 17px;
            font-weight: 800;
            text-align: center;
        }
    }

    .leader-name {
        margin-top: 10px;
        margin-left: 0px;
        background-color: white;
        border-radius: 0px;
        border-color: white;
        text-align: center;
        width: 70%;

        .p-inputgroup {
            width: max-content;

            .tttt {
                background-color: rgb(241, 241, 203);
                border-radius: 5px;
                font-size: small;
                width: 150px;
                text-align: left;
                font-weight: 800;
                text-align: center;
            }
            .p-inputgroup-addon {
                min-width: 22px;
                background-color: white;
                border-color: white;
                padding-left: 0px;
            }
        }
        .p-inputtext-view {
            font-size: 13px;
            font-weight: 600;
            text-align: center;
        }
    }
    .employee-name {
        margin-top: 7px;
        margin-left: 0px;
        background-color: white;
        border-radius: 0px;
        border-color: white;
        text-align: center;
        width: 70%;
        .p-inputgroup {
            width: max-content;

            .tttt {
                font-size: small;
                background-color: #e7dbf7;
                width: 150px;
                border-radius: 5px;
                text-align: center;
                font-weight: 600;
            }
            .p-inputgroup-addon {
                min-width: 22px;
                background-color: white;
                border-color: white;
                padding-left: 0px;
            }
        }
        .row {
            width: 90%;
            .p-inputtext-view {
                font-size: 10px;
                font-weight: 400;
            }

            .doc-ico {
                color: $timehero-light-color1;
                text-decoration: unset;
                font-weight: 600;
                font-style: normal;
            }
        }
    }
    .car-name {
        width: 200px;
        height: 50px;
        text-align: center;
        background-color: transparent;
        border-color: transparent;
        margin-top: 20px;
        .p-inputgroup {
            width: 20px;
            .tttt {
                font-size: small;
                width: 130px;
                font-weight: 500;
                text-align: left;
                background-color: transparent;
                text-align: center;
                align-items: center;
            }
            .p-inputgroup-addon {
                width: 20px;
                background-color: transparent;
                border-color: transparent;
            }
        }
    }
}

.dashboard-fullscreen-container {
    @media (min-width: 1400px) {
        .col-xxl-2 {
            width: 25%;
        }
    }
    @media (min-width: 2300px) {
        .col-xxl-2 {
            width: 15.33%;
        }
    }
    @media (min-width: 2900px) {
        .col-xxl-2 {
            width: 8.33%;
        }
    }
}
div[role='tooltip'] {
    margin-left: 10px;
}
#tooltipEmployeeStatusInfo {
    margin-left: 22px;
}
.criteria-template-buttons {
    flex-direction: row;
    display: flex;
    .p-button {
        margin-left: 10px;
    }
}

.color-picker-container {
    position: relative;
    display: inline-block;
}

.color-button {
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    outline: none;
}

.color-picker-popup {
    position: absolute;
    z-index: 100;
}




.custom-style {
    text-align: right;
    color: #3059e8;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none !important;
}


.dashboard-teil2 {
    color: var(--bs-card-color);
    background-color: white;
    min-height: 200px;
    border: transparent;
    height: 600px;
    max-width: 500px;
    min-width: 100px;
    width: auto;
    //overflow-y: scroll;
    margin-bottom: 20px;
    .p-card-header {
        font-weight: bold;
    }
    .project-name {
        margin-top: 3px;
        width: 100%;
        border-color: transparent;
        background-color: transparent;
        height: 30px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 3px;
        .card {
            background-color: yellow;
        }
        .p-inputtext-view {
            font-size: 17px;
            font-weight: 800;
            text-align: center;
        }
    }

    .leader-name {
        margin-top: 10px;
        margin-left: 0px;
        background-color: white;
        border-radius: 0px;
        border-color: white;
        text-align: center;
        width: 70%;

        .p-inputgroup {
            width: max-content;

            .tttt {
                background-color: rgb(241, 241, 203);
                border-radius: 5px;
                font-size: small;
                width: 150px;
                text-align: left;
                font-weight: 800;
                text-align: center;
            }
            .p-inputgroup-addon {
                min-width: 22px;
                background-color: white;
                border-color: white;
                padding-left: 0px;
            }
        }
        .p-inputtext-view {
            font-size: 13px;
            font-weight: 600;
            text-align: center;
        }
    }
    .employee-name {
        margin-top: 7px;
        margin-left: 0px;
        background-color: white;
        border-radius: 0px;
        border-color: white;
        text-align: center;
        width: 70%;
        .p-inputgroup {
            width: max-content;

            .tttt {
                font-size: small;
                background-color: #e7dbf7;
                width: 150px;
                border-radius: 5px;
                text-align: center;
                font-weight: 600;
            }
            .p-inputgroup-addon {
                min-width: 22px;
                background-color: white;
                border-color: white;
                padding-left: 0px;
            }
        }
        .row {
            width: 90%;
            .p-inputtext-view {
                font-size: 10px;
                font-weight: 400;
            }

            .doc-ico {
                color: $timehero-light-color1;
                text-decoration: unset;
                font-weight: 600;
                font-style: normal;
            }
        }
    }
    .car-name {
        width: 200px;
        height: 50px;
        text-align: center;
        background-color: transparent;
        border-color: transparent;
        margin-top: 20px;
        .p-inputgroup {
            width: 20px;
            .tttt {
                font-size: small;
                width: 130px;
                font-weight: 500;
                text-align: left;
                background-color: transparent;
                text-align: center;
                align-items: center;
            }
            .p-inputgroup-addon {
                width: 20px;
                background-color: transparent;
                border-color: transparent;
            }
        }
    }
}


.accDashboardUsersTable  {    
    border: none; 
    font-size: 11px;

    .p-datatable-wrapper {
        .p-datatable-table {
            .p-datatable-thead {
                font-size: 11px;
                tr {
                    font-size: 11px;
                    th {
                        font-size: 11px;
                        padding: 3px;
                    }
                }
            }   
            
            .p-datatable-tbody {        
                tr {
                    border: none ;
                    font-size: 11px;
                    td {
                        padding: 2px;
                        font-size: 11px;
                    }
                }
            }
        }
    }
    
}


