.vis-time-axis {
  position: relative;
  overflow: hidden;
}

.vis-time-axis.vis-foreground {
  top: 0;
  left: 0;
  width: 100%;
}

.vis-time-axis.vis-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vis-time-axis .vis-text {
  position: absolute;
  color: #4d4d4d;
  padding: 3px;
  overflow: hidden;
  box-sizing: border-box;

  white-space: nowrap;
}

.vis-time-axis .vis-text.vis-measure {
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  visibility: hidden;
}

.vis-time-axis .vis-grid.vis-vertical {
  position: absolute;
  border-left: 1px solid;
}

.vis-time-axis .vis-grid.vis-vertical-rtl {
  position: absolute;
  border-right: 1px solid;
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: #e5e5e5;
}

.vis-time-axis .vis-grid.vis-major {
  border-color: #bfbfbf;
}

.vis .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Must be displayed above for example selected Timeline items */
  z-index: 10;
}

.vis-active {
  box-shadow: 0 0 10px #86d5f8;
}

.vis-custom-time {
  background-color: #6E94FF;
  width: 2px;
  cursor: move;
  z-index: 1;
}

.vis-custom-time > .vis-custom-time-marker {
  background-color: inherit;
  color: white;
  font-size: 12px;
  white-space: nowrap;
  padding: 3px 5px;
  top: 0px;
  cursor: initial;
  z-index: inherit;
}
.vis-timeline {
  /*
  -webkit-transition: height .4s ease-in-out;
  transition:         height .4s ease-in-out;
  */
}

.vis-panel {
  /*
  -webkit-transition: height .4s ease-in-out, top .4s ease-in-out;
  transition:         height .4s ease-in-out, top .4s ease-in-out;
  */
}

.vis-axis {
  /*
  -webkit-transition: top .4s ease-in-out;
  transition:         top .4s ease-in-out;
  */
}

/* TODO: get animation working nicely

.vis-item {
  -webkit-transition: top .4s ease-in-out;
  transition:         top .4s ease-in-out;
}

.vis-item.line {
  -webkit-transition: height .4s ease-in-out, top .4s ease-in-out;
  transition:         height .4s ease-in-out, top .4s ease-in-out;
}
/**/
.vis-current-time {
  background-color: #FF7F6E;
  width: 2px;
  z-index: 1;
  pointer-events: none;
}

.vis-rolling-mode-btn {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 7px;
  right: 20px;
  border-radius: 50%;
  font-size: 28px;
  cursor: pointer;
  opacity: 0.8;
  color: white;
  font-weight: bold;
  text-align: center;
  background: #3876c2;
}
.vis-rolling-mode-btn:before {
  content: "\26F6";
}

.vis-rolling-mode-btn:hover {
  opacity: 1;
}
.vis-panel {
  position: absolute;

  padding: 0;
  margin: 0;

  box-sizing: border-box;
}

.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top,
.vis-panel.vis-bottom {
  border: 1px #bfbfbf;
}

.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right {
  border-top-style: solid;
  border-bottom-style: solid;
  overflow: hidden;
}

.vis-left.vis-panel.vis-vertical-scroll, .vis-right.vis-panel.vis-vertical-scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
} 

.vis-left.vis-panel.vis-vertical-scroll {
  direction: rtl;
}

.vis-left.vis-panel.vis-vertical-scroll .vis-content {
  direction: ltr;
}

.vis-right.vis-panel.vis-vertical-scroll {
  direction: ltr;
}

.vis-right.vis-panel.vis-vertical-scroll .vis-content {
  direction: rtl;
}

.vis-panel.vis-center,
.vis-panel.vis-top,
.vis-panel.vis-bottom {
  border-left-style: solid;
  border-right-style: solid;
}

.vis-background {
  overflow: hidden;
}

.vis-panel > .vis-content {
  position: relative;
}

.vis-panel .vis-shadow {
  position: absolute;
  width: 100%;
  height: 1px;
  box-shadow: 0 0 10px rgba(0,0,0,0.8);
  /* TODO: find a nice way to ensure vis-shadows are drawn on top of items
  z-index: 1;
  */
}

.vis-panel .vis-shadow.vis-top {
  top: -1px;
  left: 0;
}

.vis-panel .vis-shadow.vis-bottom {
  bottom: -1px;
  left: 0;
}
.vis-graph-group0 {
    fill:#4f81bd;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #4f81bd;
}

.vis-graph-group1 {
    fill:#f79646;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #f79646;
}

.vis-graph-group2 {
    fill: #8c51cf;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #8c51cf;
}

.vis-graph-group3 {
    fill: #75c841;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #75c841;
}

.vis-graph-group4 {
    fill: #ff0100;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #ff0100;
}

.vis-graph-group5 {
    fill: #37d8e6;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #37d8e6;
}

.vis-graph-group6 {
    fill: #042662;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #042662;
}

.vis-graph-group7 {
    fill:#00ff26;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #00ff26;
}

.vis-graph-group8 {
    fill:#ff00ff;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #ff00ff;
}

.vis-graph-group9 {
    fill: #8f3938;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #8f3938;
}

.vis-timeline .vis-fill {
    fill-opacity:0.1;
    stroke: none;
}


.vis-timeline .vis-bar {
    fill-opacity:0.5;
    stroke-width:1px;
}

.vis-timeline .vis-point {
    stroke-width:2px;
    fill-opacity:1.0;
}


.vis-timeline .vis-legend-background {
    stroke-width:1px;
    fill-opacity:0.9;
    fill: #ffffff;
    stroke: #c2c2c2;
}


.vis-timeline .vis-outline {
    stroke-width:1px;
    fill-opacity:1;
    fill: #ffffff;
    stroke: #e5e5e5;
}

.vis-timeline .vis-icon-fill {
    fill-opacity:0.3;
    stroke: none;
}


.vis-timeline {
  position: relative;
  border: 1px solid #bfbfbf;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.vis-loading-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
/* override some bootstrap styles screwing up the timelines css */

.vis [class*="span"] {
  min-height: 0;
  width: auto;
}


.vis-item {
  position: absolute;
  color: #1A1A1A;
  border-color: #97B0F8;
  border-width: 1px;
  background-color: #D5DDF6;
  display: inline-block;
  z-index: 1;
  /*overflow: hidden;*/
}

.vis-item.vis-selected {
  border-color: #FFC200;
  background-color: #FFF785;

  /* z-index must be higher than the z-index of custom time bar and current time bar */
  z-index: 2;
}

.vis-editable.vis-selected {
  cursor: move;
}

.vis-item.vis-point.vis-selected {
  background-color: #FFF785;
}

.vis-item.vis-box {
  text-align: center;
  border-style: solid;
  border-radius: 2px;
}

.vis-item.vis-point {
  background: none;
}

.vis-item.vis-dot {
  position: absolute;
  padding: 0;
  border-width: 4px;
  border-style: solid;
  border-radius: 4px;
}

.vis-item.vis-range {
  border-style: solid;
  border-radius: 2px;
  box-sizing: border-box;
}

.vis-item.vis-background {
  border: none;
  background-color: rgba(213, 221, 246, 0.4);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.vis-item .vis-item-overflow {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: grid;
  align-items: center;
}

.vis-item-visible-frame {
  white-space: nowrap;
}

.vis-item.vis-range .vis-item-content {
  position: relative;
  display: inline-block;
}

.vis-item.vis-background .vis-item-content {
  position: absolute;
  display: inline-block;
}

.vis-item.vis-line {
  padding: 0;
  position: absolute;
  width: 0;
  border-left-width: 1px;
  border-left-style: solid;
}

.vis-item .vis-item-content {
  white-space: nowrap;
  box-sizing: border-box;
  padding: 5px;
}

.vis-item .vis-onUpdateTime-tooltip {
  position: absolute;
  background: #4f81bd;
  color: white;
  width: 200px;
  text-align: center;
  white-space: nowrap;
  padding: 5px;
  border-radius: 1px;
  transition: 0.4s;
  -o-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
}

.vis-item .vis-delete, .vis-item .vis-delete-rtl {
  position: absolute;
  top: 0px;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  padding: 0px 5px;
  cursor: pointer;

  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
}

.vis-item .vis-delete {
  right: -24px;
}

.vis-item .vis-delete-rtl {
  left: -24px;
}

.vis-item .vis-delete:after, .vis-item .vis-delete-rtl:after {
  content: "\00D7"; /* MULTIPLICATION SIGN */
  color: red;
  font-family: arial, sans-serif;
  font-size: 22px;
  font-weight: bold;

  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.vis-item .vis-delete:hover, .vis-item .vis-delete-rtl:hover {
  background: red;
}

.vis-item .vis-delete:hover:after, .vis-item .vis-delete-rtl:hover:after {
  color: white;
}

.vis-item .vis-drag-center {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  cursor: move;
}

.vis-item.vis-range .vis-drag-left {
  position: absolute;
  width: 24px;
  max-width: 20%;
  min-width: 2px;
  height: 100%;
  top: 0;
  left: -4px;

  cursor: w-resize;
}

.vis-item.vis-range .vis-drag-right {
  position: absolute;
  width: 24px;
  max-width: 20%;
  min-width: 2px;
  height: 100%;
  top: 0;
  right: -4px;

  cursor: e-resize;
}

.vis-range.vis-item.vis-readonly .vis-drag-left,
.vis-range.vis-item.vis-readonly .vis-drag-right {
  cursor: auto;
}

.vis-item.vis-cluster {
  vertical-align: center;
  text-align: center;
  border-style: solid;
  border-radius: 2px;
}

.vis-item.vis-cluster-line {
  padding: 0;
  position: absolute;
  width: 0;
  border-left-width: 1px;
  border-left-style: solid;
}

.vis-item.vis-cluster-dot {
  position: absolute;
  padding: 0;
  border-width: 4px;
  border-style: solid;
  border-radius: 4px;
}
div.vis-tooltip {
  position: absolute;
  visibility: hidden;
  padding: 5px;
  white-space: nowrap;

  font-family: verdana;
  font-size:14px;
  color:#000000;
  background-color: #f5f4ed;

  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #808074;

  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  pointer-events: none;

  z-index: 5;
}


.vis-itemset {
  position: relative;
  padding: 0;
  margin: 0;

  box-sizing: border-box;
}

.vis-itemset .vis-background,
.vis-itemset .vis-foreground {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.vis-axis {
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  z-index: 1;
}

.vis-foreground .vis-group {
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #bfbfbf;
}

.vis-foreground .vis-group:last-child {
  border-bottom: none;
}

.vis-nesting-group {
  cursor: pointer;
}

.vis-label.vis-nested-group.vis-group-level-unknown-but-gte1 {
  background: #f5f5f5;
}
.vis-label.vis-nested-group.vis-group-level-0 {
  background-color: #ffffff;
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-0 .vis-inner {
  padding-left: 0;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-0 .vis-inner {
  padding-right: 0;
}
.vis-label.vis-nested-group.vis-group-level-1 {
  background-color: rgba(0, 0, 0, 0.05);
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-1 .vis-inner {
  padding-left: 15px;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-1 .vis-inner {
  padding-right: 15px;
}
.vis-label.vis-nested-group.vis-group-level-2 {
  background-color: rgba(0, 0, 0, 0.1);
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-2 .vis-inner {
  padding-left: 30px;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-2 .vis-inner {
  padding-right: 30px;
}
.vis-label.vis-nested-group.vis-group-level-3 {
  background-color: rgba(0, 0, 0, 0.15);
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-3 .vis-inner {
  padding-left: 45px;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-3 .vis-inner {
  padding-right: 45px;
}
.vis-label.vis-nested-group.vis-group-level-4 {
  background-color: rgba(0, 0, 0, 0.2);
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-4 .vis-inner {
  padding-left: 60px;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-4 .vis-inner {
  padding-right: 60px;
}
.vis-label.vis-nested-group.vis-group-level-5 {
  background-color: rgba(0, 0, 0, 0.25);
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-5 .vis-inner {
  padding-left: 75px;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-5 .vis-inner {
  padding-right: 75px;
}
.vis-label.vis-nested-group.vis-group-level-6 {
  background-color: rgba(0, 0, 0, 0.3);
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-6 .vis-inner {
  padding-left: 90px;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-6 .vis-inner {
  padding-right: 90px;
}
.vis-label.vis-nested-group.vis-group-level-7 {
  background-color: rgba(0, 0, 0, 0.35);
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-7 .vis-inner {
  padding-left: 105px;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-7 .vis-inner {
  padding-right: 105px;
}
.vis-label.vis-nested-group.vis-group-level-8 {
  background-color: rgba(0, 0, 0, 0.4);
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-8 .vis-inner {
  padding-left: 120px;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-8 .vis-inner {
  padding-right: 120px;
}
.vis-label.vis-nested-group.vis-group-level-9 {
  background-color: rgba(0, 0, 0, 0.45);
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-9 .vis-inner {
  padding-left: 135px;
}
.vis-rtl .vis-label.vis-nested-group.vis-group-level-9 .vis-inner {
  padding-right: 135px;
}
/* default takes over beginning with level-10 (thats why we add .vis-nested-group
  to the selectors above, to have higher specifity than these rules for the defaults) */
.vis-label.vis-nested-group {
  background-color: rgba(0, 0, 0, 0.5);
}
.vis-ltr .vis-label.vis-nested-group .vis-inner {
  padding-left: 150px;
}
.vis-rtl .vis-label.vis-nested-group .vis-inner {
  padding-right: 150px;
}

.vis-group-level-unknown-but-gte1 {
  border: 1px solid red;
}

/* expanded/collapsed indicators */
.vis-label.vis-nesting-group:before,
.vis-label.vis-nesting-group:before {
  display: inline-block;
  width: 15px;
}
.vis-label.vis-nesting-group.expanded:before {
  content: "\25BC";
}
.vis-label.vis-nesting-group.collapsed:before {
  content: "\25B6";
}
.vis-rtl .vis-label.vis-nesting-group.collapsed:before {
  content: "\25C0";
}
/* compensate missing expanded/collapsed indicator, but only at levels > 0 */
.vis-ltr .vis-label:not(.vis-nesting-group):not(.vis-group-level-0) {
  padding-left: 15px;
}
.vis-rtl .vis-label:not(.vis-nesting-group):not(.vis-group-level-0) {
  padding-right: 15px;
}

.vis-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.vis-labelset {
  position: relative;

  overflow: hidden;

  box-sizing: border-box;
}

.vis-labelset .vis-label {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  color: #4d4d4d;

  box-sizing: border-box;
}

.vis-labelset .vis-label {
  border-bottom: 1px solid #bfbfbf;
}

.vis-labelset .vis-label.draggable {
  cursor: pointer;
}

.vis-group-is-dragging {
  background: rgba(0, 0, 0, .1);
}

.vis-labelset .vis-label:last-child {
  border-bottom: none;
}

.vis-labelset .vis-label .vis-inner {
  display: inline-block;
  padding: 5px;
}

.vis-labelset .vis-label .vis-inner.vis-hidden {
  padding: 0;
}

div.vis-configuration {
    position:relative;
    display:block;
    float:left;
    font-size:12px;
}

div.vis-configuration-wrapper {
    display:block;
    width:700px;
}

div.vis-configuration-wrapper::after {
  clear: both;
  content: "";
  display: block;
}

div.vis-configuration.vis-config-option-container{
    display:block;
    width:495px;
    background-color: #ffffff;
    border:2px solid #f7f8fa;
    border-radius:4px;
    margin-top:20px;
    left:10px;
    padding-left:5px;
}

div.vis-configuration.vis-config-button{
    display:block;
    width:495px;
    height:25px;
    vertical-align: middle;
    line-height:25px;
    background-color: #f7f8fa;
    border:2px solid #ceced0;
    border-radius:4px;
    margin-top:20px;
    left:10px;
    padding-left:5px;
    cursor: pointer;
    margin-bottom:30px;
}

div.vis-configuration.vis-config-button.hover{
    background-color: #4588e6;
    border:2px solid #214373;
    color:#ffffff;
}

div.vis-configuration.vis-config-item{
    display:block;
    float:left;
    width:495px;
    height:25px;
    vertical-align: middle;
    line-height:25px;
}


div.vis-configuration.vis-config-item.vis-config-s2{
    left:10px;
    background-color: #f7f8fa;
    padding-left:5px;
    border-radius:3px;
}
div.vis-configuration.vis-config-item.vis-config-s3{
    left:20px;
    background-color: #e4e9f0;
    padding-left:5px;
    border-radius:3px;
}
div.vis-configuration.vis-config-item.vis-config-s4{
    left:30px;
    background-color: #cfd8e6;
    padding-left:5px;
    border-radius:3px;
}

div.vis-configuration.vis-config-header{
    font-size:18px;
    font-weight: bold;
}

div.vis-configuration.vis-config-label{
    width:120px;
    height:25px;
    line-height: 25px;
}

div.vis-configuration.vis-config-label.vis-config-s3{
    width:110px;
}
div.vis-configuration.vis-config-label.vis-config-s4{
    width:100px;
}

div.vis-configuration.vis-config-colorBlock{
    top:1px;
    width:30px;
    height:19px;
    border:1px solid #444444;
    border-radius:2px;
    padding:0px;
    margin:0px;
    cursor:pointer;
}

input.vis-configuration.vis-config-checkbox {
    left:-5px;
}


input.vis-configuration.vis-config-rangeinput{
    position:relative;
    top:-5px;
    width:60px;
    /*height:13px;*/
    padding:1px;
    margin:0;
    pointer-events:none;
}

input.vis-configuration.vis-config-range{
    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    border: 0px solid white;
    background-color:rgba(0,0,0,0);

    /*required for proper track sizing in FF*/
    width: 300px;
    height:20px;
}
input.vis-configuration.vis-config-range::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #dedede; /* Old browsers */
    background: -moz-linear-gradient(top,  #dedede 0%, #c8c8c8 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dedede), color-stop(99%,#c8c8c8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #dedede 0%,#c8c8c8 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #dedede 0%, #c8c8c8 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #dedede 0%,#c8c8c8 99%); /* IE10+ */
    background: linear-gradient(to bottom,  #dedede 0%,#c8c8c8 99%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */

    border: 1px solid #999999;
    box-shadow: #aaaaaa 0px 0px 3px 0px;
    border-radius: 3px;
}
input.vis-configuration.vis-config-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #14334b;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #3876c2; /* Old browsers */
    background: -moz-linear-gradient(top,  #3876c2 0%, #385380 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3876c2), color-stop(100%,#385380)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #3876c2 0%,#385380 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #3876c2 0%,#385380 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #3876c2 0%,#385380 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #3876c2 0%,#385380 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3876c2', endColorstr='#385380',GradientType=0 ); /* IE6-9 */
    box-shadow: #111927 0px 0px 1px 0px;
    margin-top: -7px;
}
input.vis-configuration.vis-config-range:focus {
    outline: none;
}
input.vis-configuration.vis-config-range:focus::-webkit-slider-runnable-track {
    background: #9d9d9d; /* Old browsers */
    background: -moz-linear-gradient(top, #9d9d9d 0%, #c8c8c8 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#9d9d9d), color-stop(99%,#c8c8c8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #9d9d9d 0%,#c8c8c8 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #9d9d9d 0%,#c8c8c8 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #9d9d9d 0%,#c8c8c8 99%); /* IE10+ */
    background: linear-gradient(to bottom,  #9d9d9d 0%,#c8c8c8 99%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9d9d9d', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */
}

input.vis-configuration.vis-config-range::-moz-range-track {
    width: 300px;
    height: 10px;
    background: #dedede; /* Old browsers */
    background: -moz-linear-gradient(top,  #dedede 0%, #c8c8c8 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dedede), color-stop(99%,#c8c8c8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #dedede 0%,#c8c8c8 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #dedede 0%, #c8c8c8 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #dedede 0%,#c8c8c8 99%); /* IE10+ */
    background: linear-gradient(to bottom,  #dedede 0%,#c8c8c8 99%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */

    border: 1px solid #999999;
    box-shadow: #aaaaaa 0px 0px 3px 0px;
    border-radius: 3px;
}
input.vis-configuration.vis-config-range::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;

    border-radius: 50%;
    background:  #385380;
}

/*hide the outline behind the border*/
input.vis-configuration.vis-config-range:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
}

input.vis-configuration.vis-config-range::-ms-track {
    width: 300px;
    height: 5px;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
}
input.vis-configuration.vis-config-range::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
}
input.vis-configuration.vis-config-range::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
}
input.vis-configuration.vis-config-range::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background:  #385380;
}
input.vis-configuration.vis-config-range:focus::-ms-fill-lower {
    background: #888;
}
input.vis-configuration.vis-config-range:focus::-ms-fill-upper {
    background: #ccc;
}

.vis-configuration-popup {
    position: absolute;
    background: rgba(57, 76, 89, 0.85);
    border: 2px solid #f2faff;
    line-height:30px;
    height:30px;
    width:150px;
    text-align:center;
    color: #ffffff;
    font-size:14px;
    border-radius:4px;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}
.vis-configuration-popup:after, .vis-configuration-popup:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.vis-configuration-popup:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: rgba(57, 76, 89, 0.85);
    border-width: 8px;
    margin-top: -8px;
}
.vis-configuration-popup:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #f2faff;
    border-width: 12px;
    margin-top: -12px;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-horizontal {
  position: absolute;
  width: 100%;
  height: 0;
  border-bottom: 1px solid;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-minor {
  border-color: #e5e5e5;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-major {
  border-color: #bfbfbf;
}


.vis-data-axis .vis-y-axis.vis-major {
  width: 100%;
  position: absolute;
  color: #4d4d4d;
  white-space: nowrap;
}

.vis-data-axis .vis-y-axis.vis-major.vis-measure {
  padding: 0;
  margin: 0;
  border: 0;
  visibility: hidden;
  width: auto;
}


.vis-data-axis .vis-y-axis.vis-minor {
  position: absolute;
  width: 100%;
  color: #bebebe;
  white-space: nowrap;
}

.vis-data-axis .vis-y-axis.vis-minor.vis-measure {
  padding: 0;
  margin: 0;
  border: 0;
  visibility: hidden;
  width: auto;
}

.vis-data-axis .vis-y-axis.vis-title {
  position: absolute;
  color: #4d4d4d;
  white-space: nowrap;
  bottom: 20px;
  text-align: center;
}

.vis-data-axis .vis-y-axis.vis-title.vis-measure {
  padding: 0;
  margin: 0;
  visibility: hidden;
  width: auto;
}

.vis-data-axis .vis-y-axis.vis-title.vis-left {
  bottom: 0;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left bottom;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.vis-data-axis .vis-y-axis.vis-title.vis-right {
  bottom: 0;
  -webkit-transform-origin: right bottom;
  -moz-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  -o-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.vis-legend {
  background-color: rgba(247, 252, 255, 0.65);
  padding: 5px;
  border: 1px solid #b3b3b3;
  box-shadow: 2px 2px 10px rgba(154, 154, 154, 0.55);
}

.vis-legend-text {
  /*font-size: 10px;*/
  white-space: nowrap;
  display: inline-block
}

.vis-item.absence {
  background-color: gold;
  border-color: orange;
  height: 34px;
}

.vis-item.green {
  background-color: greenyellow;
  border-color: green;
}

/* create a custom sized dot at the bottom of the red item */
.vis-item.red {
  background-color: red;
  border-color: darkred;
  color: white;
  font-family: monospace;
  box-shadow: 0 0 10px gray;
}
.vis-item.vis-dot.red {
  border-radius: 10px;
  border-width: 10px;
}
.vis-item.vis-line.red {
  border-width: 5px;
}
.vis-item.vis-box.red {
  border-radius: 0;
  border-width: 2px;
  font-size: 24pt;
  font-weight: bold;
}

.vis-item.orange {
  background-color: gold;
  border-color: orange;
}
.vis-item.vis-selected.orange {
  /* custom colors for selected orange items */
  background-color: orange;
  border-color: orangered;
}

.vis-item.magenta {
  background-color: magenta;
  border-color: purple;
  color: white;
}
